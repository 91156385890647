import * as bootstrap from "bootstrap";
import { useMemo, useEffect, useState, useCallback } from "react";
import usePortal from "react-useportal";
import { PdfPreview } from "./preview/pdfPreview";
import { BlobRow } from "../../types/supabase";
import { useClient } from "../providers/supabase";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import { isMobile } from "../../lib/util/isMobile";
import { downloadFile } from "../../lib/util/downloadFile";

import './filePreview.scss';

export interface FilePreviewProps {
  file: File,
  onDelete?: () => void
}

export function FilePreview({file, onDelete}: FilePreviewProps) {
  
  const isImage = file.type.startsWith('image/') || ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'ico'].includes(file.name.split('.').pop()!)
  const isPdf = file.type.startsWith('application/pdf') || file.name.endsWith('.pdf')
  
  let previewElement: React.ReactNode

  if (isImage) {
    previewElement = <img src={URL.createObjectURL(file)} alt='preview' style={{ maxWidth: '100%' }} />
  } else if (isPdf) {
    if (isMobile()) {
      previewElement = <PdfPreview file={file} />
    } else {
      // On desktop, we can use an iframe to render the PDF.
      previewElement = <iframe src={URL.createObjectURL(file)} style={{ width: '100%', height: '100vh' }} />
    }
  } else {
    if (isMobile()) {
      previewElement = <div>No mobile preview available for files of type {file.type}</div>
    } else {
      // On desktop, try an iFrame for any other file type.
      previewElement = <iframe src={URL.createObjectURL(file)} style={{ width: '100%', height: '100vh' }} />
    }
  }

  return <div className="card file-preview" onDoubleClick={() => {
    window.open(URL.createObjectURL(file))
  }}>
    <div className="card-body">
      <div className="card-text">
        {previewElement}

        {onDelete &&
          <button className='btn btn-link quick-add__blob__delete'
              onClick={onDelete}>
            <i className='material-icons'>delete</i>
          </button>}
      </div>
    </div>
  </div>
}

type FilePreviewModalProps = {
  show?: boolean | null | undefined
  
  fileOrBlob?: File | BlobRow | null | undefined

  onClose?: () => void
}


export function FilePreviewModal(props: React.PropsWithChildren<FilePreviewModalProps>) {
  const { Portal } = usePortal()
  const client = useClient()
  
  const uniqueId = useMemo<number>(() => Math.floor(Math.random() * 10_000), [])
  const domId = `modal-file-preview-${uniqueId}`
  
  const showModal = useCallback(() => {
    const modalElem = document.getElementById(domId)
    const modal =
      bootstrap.Modal.getInstance(modalElem!) ||
      new bootstrap.Modal(modalElem!, {
        backdrop: 'static',
        keyboard: false,
      })
    modal?.show()
  }, [domId])
  
  const hideModal = useCallback((evt?: React.MouseEvent<HTMLButtonElement>) => {
    if (evt) {
      evt.preventDefault()
      evt.stopPropagation()
    }
    const modalElem = document.getElementById(domId)
    const modal = bootstrap.Modal.getInstance(modalElem!)
    modal?.hide()
    
    props.onClose?.()
  }, [domId, props.onClose])
  
  useEffect(() => { 
    if (props.show) {
      showModal()
    } else {
      hideModal()
    }
  }, [props.show, showModal, hideModal])
  
  const [file, setFile] = useState<File | null | undefined>()
  
  // If the incoming file changes, update the local file state.
  const {loading, refreshing, error} = useAsyncEffect(async () => {
    if (!props.fileOrBlob) { return }

    if (props.fileOrBlob instanceof File) {
      setFile(props.fileOrBlob)
    } else {
      const file = await client.storage.from('wcc-hra-calculator-private').download(props.fileOrBlob.file_full_path)
      if (file.error) { throw new Error(file.error.message, { cause: file.error }) }

      setFile(new File([file.data], props.fileOrBlob.file_name, { type: props.fileOrBlob.content_type }))
    }
  }, [props.fileOrBlob])

  return <>
    {props.children}
    <Portal>
      <div className="modal modal-lg fade file-preview-modal" id={domId} aria-hidden={!props.show}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{file?.name}</h5>
              
              <button type="button" className="btn btn-link" onClick={() => {
                if (file) {
                  downloadFile(file.name, file)
                }
              }}>
                <i className="material-icons">download</i>
              </button>
              
              <button type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={hideModal}
              ></button>
            </div>
            <div className="modal-body">
              {file && props.show ?
                <FilePreview file={file} /> :
                loading || refreshing ?
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div> :
                  <span>No file selected</span>}
              {error && <span>Error loading file: {error.message}</span>}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  </>
}
