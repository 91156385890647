import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxToolkit';
import { providerDataSourceLabel, ProviderUpdatePayload, updateProvider } from '../../reduxToolkit/providersSlice';
import { NotFound } from '../../screens/notFound';
import { useState } from 'react';
import { present } from '../../../lib/util/present';

import './editProvider.scss';

export function EditProvider() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  
  const provider = useAppSelector((state) => state.providers?.providers.find((p) => p.id === id));
  const membershipId = useAppSelector((state) => state.membership.membershipId);

  const dispatch = useAppDispatch();
  const [name, setName] = useState(provider?.name ?? '');
  const [phone, setPhone] = useState(provider?.billing_phone ?? '');
  const [address, setAddress] = useState(provider?.street_address ?? '');
  const [email, setEmail] = useState(provider?.billing_email ?? '');
  const [website, setWebsite] = useState(provider?.website ?? '');
  if (!provider) {
    return <NotFound />;
  }
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!id) {
      return;
    }
    
    const now = new Date().toISOString()
    
    const didChangePhone = present(phone) && phone !== provider.billing_phone
    const didChangeEmail = present(email) && email !== provider.billing_email
    const didChangeAddress = present(address) && address !== provider.street_address
    const didChangeWebsite = present(website) && website !== provider.website
    
    const providerUpdate: ProviderUpdatePayload = {
      id,
      membership_id: membershipId,
      name,
      billing_phone: phone,
      billing_phone_source: didChangePhone ? 'manual' : provider.billing_phone_source,
      billing_email: email,
      billing_email_source: didChangeEmail ? 'manual' : provider.billing_email_source,
      street_address: address,
      street_address_source: didChangeAddress ? 'manual' : provider.street_address_source,
      website: website,
      website_source: didChangeWebsite ? 'manual' : provider.website_source,
      created_at: now,
      updated_at: now
    };

    dispatch(updateProvider(providerUpdate));
    navigate(`/providers/${id}`);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1 className="edit-provider__title">Edit Provider</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="provider-name" className="form-label">Provider Name</label>
              <input
                type="text"
                className="form-control"
                id="provider-name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                placeholder="Enter provider name"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="provider-phone" className="form-label">Phone Number</label>
              <input
                type="tel"
                className="form-control"
                id="provider-phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter phone number"
              />
              {provider.billing_phone_source && (provider.billing_phone === phone) &&
                <div className="form-text">
                  <span className="text-muted text-sm">
                    {providerDataSourceLabel(provider.billing_phone_source)}
                  </span>
                </div>}
            </div>

            <div className="mb-3">
              <label htmlFor="provider-email" className="form-label">Billing Email</label>
              <input
                type="email"
                className="form-control"
                id="provider-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter billing email"
              />
              {provider.billing_email_source && (provider.billing_email === email) &&
                <div className="form-text">
                  <span className="text-muted text-sm">
                    {providerDataSourceLabel(provider.billing_email_source)}
                  </span>
                </div>}
            </div>

            <div className="mb-3">
              <label htmlFor="provider-website" className="form-label">Website</label>
              <input
                type="url"
                className="form-control"
                id="provider-website"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                placeholder="Enter website"
              />
              {provider.website_source && (provider.website === website) &&
                <div className="form-text">
                  <span className="text-muted text-sm">
                    {providerDataSourceLabel(provider.website_source)}
                  </span>
                </div>}
            </div>

            <div className="mb-3">
              <label htmlFor="provider-address" className="form-label">Address</label>
              <textarea
                className="form-control"
                id="provider-address"
                rows={3}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter provider address"
              />
              {provider.street_address_source && (provider.street_address === address) &&
                <div className="form-text">
                  <span className="text-muted text-sm">
                    {providerDataSourceLabel(provider.street_address_source)}
                  </span>
                </div>}
            </div>

            <button type="submit" className="btn btn-primary">
              Save Provider
            </button>
          </form>
        </div>
      </div>
    </div>
  );
} 
