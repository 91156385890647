import { ProviderModel } from "../providersSlice";
import { RootState } from "../store";
import fuzzysort from 'fuzzysort';

export function selectDuplicateProvider(provider: ProviderModel): (s: RootState) => ProviderModel | null | undefined {
  return (s: RootState) => {
    // A duplicate provider is one that matches on 2 of the following:
    // - name
    // - street_address
    // - billing_email
    // - billing_phone
    // - website
    
    return s.providers?.providers?.filter((p) => p.id !== provider.id)
      .find((p) => {
        const matches = [
          fuzzyMatch(p.name, provider.name),
          fuzzyMatch(p.street_address, provider.street_address),
          p.billing_email === provider.billing_email,
          p.billing_phone === provider.billing_phone,
          p.website === provider.website,
        ]
        return matches.filter(Boolean).length >= 2
      })
  }
}

function fuzzyMatch(a: string | null | undefined, b: string | null | undefined): boolean {
  if (!a || !b) return false;
  
  // Normalize strings
  const str1 = a.toLowerCase().trim();
  const str2 = b.toLowerCase().trim();
  
  // Exact match
  if (str1 === str2) return true;
  
  // Use fuzzysort to compare strings
  const result = fuzzysort.single(str1, str2);
  
  // Result will be null if no match found
  // Otherwise, check if the match score is good enough (adjust threshold as needed)
  return result !== null && result.score > 0.6;
}
