import JSZip from "jszip"
import path from "path-browserify"
import { BlobRow } from "../../types/supabase"
import { useClient } from "../providers/supabase"
import { useAsyncAction } from "./useAsyncAction"
import { useCallback } from "react"

/**
 * Provides a function to invoke the native share dialog with a zip file containing the provided blobs.
 * 
 * Returns a second function to check if the native share dialog is available.
 */
export function useShareBlobs(blobs: BlobRow[] | undefined, zipFileName: string) {
  const client = useClient()
  
  const [result, shareBlobs] = useAsyncAction(async () => {
    if (!blobs || blobs.length === 0) {
      throw new Error('No files to share')
    }
    if (typeof navigator.share !== 'function') {
      throw new Error('Your browser does not support sharing files')
    }
    
    const zip = new JSZip()
    await Promise.all(blobs.map(async (b) => {
      const result = await client.storage.from('wcc-hra-calculator-private').download(b.file_full_path)
      if (result.error) { throw result.error }
      
      const fileNameInZip = path.basename(b.file_name)
      zip.file(fileNameInZip, result.data)
    }))

    const zipFile = await zip.generateAsync({ type: 'blob' })

    navigator.share({
      files: [
        new File([zipFile], `${zipFileName}.zip`, {
          type: 'application/zip'
        })
      ]
    })
  }, [blobs, client, zipFileName])
  
  const canShareBlobs = useCallback(() => {
    if (!blobs || blobs.length === 0) {
      return false
    }
    if (typeof navigator.share !== 'function') {
      return false
    }
    
    if (!navigator.canShare({
      files: [
        new File([], `${zipFileName}.zip`, {
          type: 'application/zip'
        })
      ]
    })) {
      return false
    }
    
    return true
  }, [blobs, zipFileName])
  
  return [
    shareBlobs,
    result,
    {
      canShareBlobs
    }
  ] as const
}
