import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxToolkit';
import { EditExpenseOnUpdatedFn, EditExpenseRowForm } from '../../components/forms/editExpenseRowForm';
import { NotFound } from '../../screens/notFound';
import { useLoadBlobs } from '../../hooks/useLoadBlobs';
import { SnapAPhoto, SnapAPhotoOnUploadParams } from '../../components/snapAPhoto';
import { BlobRow } from '../../../types/supabase';
import { useConfirm } from '../../providers/confirmDialog';
import { FilePreviewModal } from '../../components/filePreview';
import { createAttachment, deleteAttachment } from '../../reduxToolkit/attachmentsSlice';
import { humanize } from '../../../lib/util/string';
import { useNavigateToNewExpense } from '../../hooks/useNavigateToExpense';

import './editExpense.scss'

export function EditExpense() {
  const params = useParams()
  const navigateToNewExpense = useNavigateToNewExpense()
  const location = useLocation()
  const [confirm] = useConfirm()
  const dispatch = useAppDispatch()

  const validateOnMount = location.state?.validateOnMount
  const disabledFields = location.state?.disabledFields
  
  const expense = useAppSelector((s) => s.expenses.expenses?.find((e) => e.id == params.expenseId))
  const attachments = useAppSelector((s) => s.attachments.attachments?.filter((a) =>
    a.record_id == expense?.id && a.table_name == 'expenses')) || []
  
  const [existingAttachmentBlobs, existingAttachmentBlobsLoading] = useLoadBlobs(attachments.map((a) => a.blob_key))
  
  const onSubmit: EditExpenseOnUpdatedFn = (expense) => {
    const now = new Date().toISOString()

    // Add any new attachments to the expense
    const newAttachments = uploadedBlobs
      .filter(({blob}) => !blobKeysPendingDelete.includes(blob.key))
    for (const attachment of newAttachments) {
      dispatch(createAttachment({
        id: uuidv4(),
        blob_key: attachment.blob.key,
        created_at: now,
        membership_id: expense.membership_id,
        record_id: expense.id,
        table_name: 'expenses',
        updated_at: now,
        purpose: attachment.purpose,
      }))
    }
    
    for (const key of blobKeysPendingDelete) {
      const attachment = attachments?.find((a) => a.blob_key === key)  
      if (attachment) {
        dispatch(deleteAttachment({
          id: attachment.id,
          updated_at: now,
          deleted_at: now,
        }))
      }
    }
    
    navigateToNewExpense(expense)
  }
  const [uploadedBlobs, setUploadedBlobs] = useState<SnapAPhotoOnUploadParams[]>([])
  const [blobKeysPendingDelete, setBlobKeysPendingDelete] = useState<string[]>([])
  
  const [previewFile, setPreviewFile] = useState<File | BlobRow | null>(null)
  
  const onUpload = useCallback(async (params: SnapAPhotoOnUploadParams) => {
    setUploadedBlobs((prev) => [...prev, params])
  }, [])
  
  const onDeleteBlob = useCallback((blobKey: string) => {
    console.log('setting blob key to delete', blobKey)
    setBlobKeysPendingDelete((prev) => [...prev, blobKey])
  }, [])
  
  if (!expense) {
    return <NotFound />
  }

  return <div className="edit-expense">
    <div className="row">
      <div className="col-12 col-md-8">
        <h1 className="edit-expense__title">Edit Expense</h1>
        <p className="edit-expense__subtitle">
          Enter the details of your expense below.
          You can also upload an itemized bill to attach it to this expense.
        </p>
        <EditExpenseRowForm
            expense={expense}
            onSubmit={onSubmit}
            validateOnMount={validateOnMount}
            disabledFields={disabledFields}>

          <div className='row edit-expense__attachments mt-4'>
            <div className='col-12'>
              <h3>Attachments</h3>
              {existingAttachmentBlobs && existingAttachmentBlobs.length > 0 &&
                <div className='row edit-expense__attachments-header'>
                  <div className='col-7'>
                    Name
                  </div>
                  <div className='col-3'>
                    Purpose
                  </div>
                  <div className='col-2 d-flex justify-content-end'>
                    &nbsp;
                  </div>
                </div>
              }
              {existingAttachmentBlobsLoading.loading || existingAttachmentBlobsLoading.refreshing ?
                <div className='row'>
                  <div className='col-12'>
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div> :
                (attachments || [])
                  .filter((a) => !blobKeysPendingDelete.includes(a.blob_key))
                  .map((attachment) => {
                    const blob = existingAttachmentBlobs?.find((b) => b.key === attachment.blob_key)
                    if (!blob) {
                      return null
                    }
                    
                    return <div className='row edit-expense__attachments-row' key={blob.key}>
                      <div className='col-7 d-flex align-items-center'>
                        {contentTypeIcon(blob.content_type)}
                        <span className='edit-expense__attachments-row-name text-truncate me-2'>{blob.file_name}</span>
                      </div>
                      <div className='col-3'>
                        {humanize(attachment.purpose)}
                      </div>
                      <div className='col-2 d-flex justify-content-end'>
                        <button className='btn btn-link' onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setPreviewFile(blob)
                        }}>
                          <i className='material-icons'>open_in_new</i>
                        </button>
                        <button className='btn btn-link'
                            onClick={() => {
                              confirm({
                                message: 'Are you sure you want to delete this attachment?'
                              }).then((confirmed) => {
                                if (confirmed) {
                                  console.log('deleting', blob.key)
                                  onDeleteBlob(blob.key)
                                }
                              })
                            }}>
                          <i className='material-icons'>delete</i>
                        </button>
                      </div>
                    </div>
                  })}
                  
                {existingAttachmentBlobsLoading.error &&
                  <div className='row'>
                    <div className='col-12'>
                      <div className="alert alert-danger">
                        {existingAttachmentBlobsLoading.error.message}
                      </div>
                    </div>
                  </div>
                }

              {(uploadedBlobs || [])
                .filter((upload) => !blobKeysPendingDelete.includes(upload.blob.key))
                .map((upload) =>
                  <div className='row edit-expense__attachments-row' key={upload.blob.key}>
                    <div className='col-7'>
                      {contentTypeIcon(upload.blob.content_type)}
                      <span className='edit-expense__attachments-row-name text-truncate me-2'>{upload.blob.file_name}</span>
                    </div>
                    <div className='col-3'>
                      {humanize(upload.purpose)}
                    </div>
                    <div className='col-2 d-flex justify-content-end'>
                      <button className='btn btn-link' onClick={() => setPreviewFile(upload.file)}>
                        <i className='material-icons'>open_in_new</i>
                      </button>
                      
                      <button className='btn btn-link' onClick={() => {
                        confirm({
                          message: 'Are you sure you want to delete this attachment?',
                        }).then((confirmed) => {
                          if (confirmed) {
                            console.log('deleting', upload.blob.key)
                            onDeleteBlob(upload.blob.key)
                          }
                        })
                      }}>
                        <i className='material-icons'>delete</i>
                      </button>
                    </div>
                  </div>
                )}
              
              <div className="row mt-2 d-flex justify-content-center">
                <SnapAPhoto onUpload={onUpload}>
                  <button className='btn btn-outline-primary'>+ Add Attachment</button>
                </SnapAPhoto>
              </div>
            </div>
          </div>
        </EditExpenseRowForm>
      </div>
    </div>
    
    <FilePreviewModal show={!!previewFile} fileOrBlob={previewFile} onClose={() => setPreviewFile(null)} />
  </div>
} 

function contentTypeIcon(contentType: string) {
  if (contentType.startsWith('image/')) {
    return <i className='material-icons'>image</i>
  }
  // pdf
  if (contentType.startsWith('application/pdf')) {
    return <i className='material-icons'>picture_as_pdf</i>
  }
  
  return <i className='material-icons'>file_present</i>
}
