import { useLocation, useNavigate } from 'react-router-dom';
import { NewExpenseRowForm } from '../../components/forms/newExpenseRowForm';
import { useMemo } from 'react';
import { useNavigateToNewExpense } from '../../hooks/useNavigateToExpense';

import './newExpense.scss'

export function NewExpense() {
  const navigateToNewExpense = useNavigateToNewExpense()
  const location = useLocation()
  
  const incidentId = location.state?.incident_id
  const prefilledData = useMemo(() => location.state?.prefilledData ?? {}, [])
  const allowNewIncident =location.state?.allowNewIncident ?? true
  const disabledFields = useMemo(() => location.state?.disabledFields ?? [], [])

  return <div className="container">
    <div className="row">
      <div className="col-12">
        <h1 className="new-expense__title">Enter New Expense</h1>
        <p className="new-expense__subtitle">
          Enter the details of your expense below.
          <br/>
          You can attach an itemized bill after you finish entering the expense, by tapping the "Edit" button.
        </p>
        <NewExpenseRowForm
          prefilledData={prefilledData}
          incidentId={incidentId}
          allowNewIncident={allowNewIncident}
          disabledFields={disabledFields}
          onInserted={navigateToNewExpense} />
      </div>
    </div>
  </div>
} 
