import { assert } from "../util/assert"

const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY

const fields = [
  'places.id',
  'places.displayName',
  'places.formattedAddress',
  'places.websiteUri',
  'places.nationalPhoneNumber',
]

export async function searchPlacesNew(query: string, options: { limit: number, fieldMask?: string[] } = { limit: 10, fieldMask: fields }) {
  assert(apiKey, 'Google Maps API key is not set')
  
  const response = await fetch(`https://places.googleapis.com/v1/places:searchText`, {
    method: 'POST',
    body: JSON.stringify({
      textQuery: query,
      pageSize: options.limit || 10,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': apiKey,
      'X-Goog-FieldMask': (options.fieldMask || fields).join(','),
    },
  })
  const data: GooglePlacesSearchResponse = await response.json()
  return data
}

interface GooglePlacesSearchResponse {
  places: GooglePlaceResult[]
}

export interface GooglePlaceResult {
  name: string
  id: string
  types: string[]
  nationalPhoneNumber: string
  internationalPhoneNumber: string
  formattedAddress: string
  addressComponents: AddressComponent[]
  plusCode: {
    globalCode: string
    compoundCode: string
  }
  location: {
    latitude: number
    longitude: number
  }
  viewport: {
    low: {
      latitude: number
      longitude: number
    }
    high: {
      latitude: number
      longitude: number
    }
  }
  rating: number
  googleMapsUri: string
  websiteUri: string
  regularOpeningHours: {
    openNow: boolean
    periods: Hours[]
  }
  utcOffsetMinutes: number
  adrFormatAddress: string
  businessStatus: string
  userRatingCount: number
  iconMaskBaseUri: string
  iconBackgroundColor: string
  displayName: {
    text: string
    languageCode: string
  }
  primaryTypeDisplayName: {
    text: string
    languageCode: string
  }
  delivery: boolean
  currentOpeningHours: {
    openNow: boolean
    periods: Hours[]
  }
  currentSecondaryOpeningHours: {
    openNow: boolean
    periods: Hours[]
  }
  regularSecondaryOpeningHours: {
    openNow: boolean
    periods: Hours[]
  }
  primaryType: string
  shortFormattedAddress: string
  
  
  paymentOptions?: {
    acceptsCreditCards: boolean
    acceptsDebitCards: boolean
    acceptsCashOnly: boolean
  },
  accessibilityOptions?: {
    wheelchairAccessibleParking: boolean
    wheelchairAccessibleEntrance: boolean
  },
  addressDescriptor?: {
    landmarks?: Landmark[]
    areas: Area[]
  },
  googleMapsLinks?: {
    directionsUri: string
    placeUri: string
    writeAReviewUri: string
    reviewsUri: string
    photosUri: string
  }
}

interface Landmark {
  name: string
  placeId: string
  displayName: {
    text: string
    languageCode: string
  }
  types: string[]
}

interface Area {
  name: string
  placeId: string
  displayName: {
    text: string
    languageCode: string
  }
  containment: string
}

interface AddressComponent {
  longText: string
  shortText: string
  types: string[]
  languageCode: string
}

interface Hours {
  open: {
    day: number
    hour: number
    minute: number
    date: {
      year: number
      month: number
      day: number
    }
  }
  close: {
    day: number
    hour: number
    minute: number
    date: {
      year: number
      month: number
      day: number
    }
  }
}
