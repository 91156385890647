import { useCallback, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { AppSupabaseClient, AttachmentPurpose, BlobRow } from "../../types/supabase";
import { useAppDispatch, useAppSelector } from "./reduxToolkit";
import { useClient } from "../providers/supabase";
import { ExpenseInsertPayload, ExpenseModel, addExpense } from "../reduxToolkit/expensesSlice";
import { AttachmentModel, createAttachment } from "../reduxToolkit/attachmentsSlice";
import merge from "lodash/merge";
import { useSentry } from "../providers/sentry";
import { AppDispatch } from "../reduxToolkit/store";
import { onSyncDownComplete } from "../reduxToolkit/actions/onSyncDownComplete";
import { useTextractOptInConfirm } from "./useTextractOptInConfirm";

export interface BlobOCRResult { blob: BlobRow, file: File, expense: ExpenseModel, attachment: AttachmentModel }

interface UseBlobOcrParams {
  onCompleted?: (result: BlobOCRResult) => void

  prefilledExpense?: Partial<ExpenseModel>
}

export function useBlobOcr(params?: UseBlobOcrParams) {
  const dispatch = useAppDispatch()
  const client = useClient()
  const sentry = useSentry()

  const {membershipId, userId} = useAppSelector((s) => s.membership)
  const [optedInToOCR, confirmOptIn] = useTextractOptInConfirm()

  const {onCompleted, prefilledExpense} = params || {}
  
  const [state, setState] = useState<BlobOCRResult | null>(null)
  const [error, setError] = useState<Error | null>(null)

  /**
   * Creates a new expense out of the blob and creates an attachment from the expense
   * to the blob.
   */
  const createExpenseFromBlob = async ({blob, file, purpose}: {blob: BlobRow, file: File, purpose: AttachmentPurpose}) => {
    try {
      let now = new Date().toISOString()

      const expenseId = uuidv4()
      const defaultExpenseData: ExpenseInsertPayload = {
        id: expenseId,
        created_at: now,
        updated_at: now,
        created_by_user_id: userId,
        membership_id: membershipId,
      }

      const expenseData = merge(
        defaultExpenseData,
        // The prefilled expense data takes precedence
        prefilledExpense,
      )

      const addExpenseAction = dispatch(addExpense(expenseData))

      const createAttachmentAction = dispatch(createAttachment({
        id: uuidv4(),
        record_id: addExpenseAction.payload.id,
        table_name: 'expenses',
        blob_key: blob.key,
        membership_id: membershipId,
        updated_at: now,
        created_at: now,
        purpose,
      }))
      const completedState = {
        blob,
        file,
        expense: addExpenseAction.payload,
        attachment: createAttachmentAction.payload,
      }
      
      let shouldDoOCR: boolean = optedInToOCR
      if (!shouldDoOCR) {
        shouldDoOCR = await confirmOptIn()
      }

      if (shouldDoOCR) {
        // In the background, start the OCR job
        setTimeout(() => {
          uploadExpenseAndStartOCR({
            expense: addExpenseAction.payload,
            attachment: createAttachmentAction.payload,
            blob,
          }, {
            supabase: client,
            dispatch,
          }).catch((e) => {
            console.error(e)
            sentry.captureException(e)
            // Not necessary to inform the user of this error
          })
        }, 0)
      }

      setState(completedState)
      onCompleted?.(completedState)
    } catch (e) {
      console.error(e)
      sentry.captureException(e)
      setError(e as Error)
    }
  }

  return [
    state,
    {
      error,
      reset: useCallback(() => { setState(null); setError(null) }, []),
    },
    createExpenseFromBlob
  ] as const
}

async function uploadExpenseAndStartOCR({
  expense,
  attachment,
  blob,
}:{
  expense: ExpenseModel,
  attachment: AttachmentModel,
  blob: BlobRow,
}, {
  supabase,
  dispatch
}: {
  supabase: AppSupabaseClient,
  dispatch: AppDispatch
}) {
  // Ensure the expense and attachment are uploaded to the server.
  const responses = await Promise.all([
    supabase.from('expenses').insert(expense),
    supabase.from('blobs').insert(blob)
  ])
  // The attachment must be created after the Expense and Blob are created
  responses.push(await supabase.from('attachments').insert(attachment))
  for(const resp of responses) {
    if (resp.error) {
      // Duplicate inserts are OK, just means the expense was already uploaded by sync
      if (!resp.error.message.includes('violates unique constraint')) {
        throw resp.error
      }
    }
  }
  
  // Invoke Amazon Textract
  const response = await supabase.functions.invoke('start-document-analysis', {
    body: JSON.stringify({key: blob.key, expenseId: expense.id}),
    method: 'POST',
  })
  if (response.error) { throw response.error }
  
  const updatedExpense = response.data?.data
  if (!updatedExpense) { throw new Error('No expense returned from server') }
  
  // the server has updated the job ID and status
  dispatch(onSyncDownComplete({
    expenses: { updated: [updatedExpense] }
  }))
}
