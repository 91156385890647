import { v4 as uuidv4 } from 'uuid';
import format from "date-fns/format"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxToolkit"
import { present } from "../../../lib/util/present"
import { selectPatients } from "../../reduxToolkit/selectors/dependents"
import { PatientSelect, addIdToPatient } from "../formComponents/patientSelect"
import { assert } from "../../../lib/util/assert"
import { addExpense, ExpenseInsertPayload } from "../../reduxToolkit/expensesSlice"
import { parseDollarAmount } from '../../../lib/util/parse';
import isEqual from 'lodash/isEqual'
import { IncidentInsertPayload, addIncident, updateIncident } from '../../reduxToolkit/incidentsSlice';
import { IncidentInputGroup, IncidentInputGroupValue } from '../formComponents/incidentInputGroup';
import { useCustomization } from '../../hooks/useCustomizations';
import { Tooltip } from '../tooltip';
import Decimal from 'decimal.js';
import { NotNull } from '../../../types/util';
import { ProviderSelect } from '../formComponents/providerSelect'
import { calculateRemainingAmounts } from '../../../lib/util/calculateRemainingAmounts';
import { entries } from 'lodash';

import './newExpenseRowForm.scss'

export type NewExpenseOnInsertedFn = (expense: ExpenseInsertPayload, incident?: IncidentInsertPayload | null) => void

type NewExpenseFormModel = {
  date: string
  patient_name: string
  patient_dob: string
  listedAmount?: string
  discountAmount?: string
  paidAmount?: string
  remainingOwedAmount?: string
  provider: string | null
  provider_id?: string | null
  incident_id?: string | null
  paid_with_hra?: boolean
  is_prepayment_agreement?: boolean
}

export type NewExpenseRowFormProps = {
  onInserted?: NewExpenseOnInsertedFn

  prefilledData?: Partial<NewExpenseFormModel>
  disabledFields?: Array<keyof NewExpenseFormModel>
  /**
   * Provide the incident ID where this expense row should go.
   * Cannot be used with `allowNewIncident`
   */
  incidentId?: string
  allowNewIncident?: boolean
}

type ValidationErrors = {
  [key in keyof NewExpenseFormModel]?: string
}

export function NewExpenseRowForm({
  onInserted,
  incidentId: requiredIncidentId,
  allowNewIncident,
  prefilledData,
  disabledFields
}: NewExpenseRowFormProps) {
  const dispatch = useAppDispatch()
  const {membershipId, userId} = useAppSelector((s) => s.membership)
  const patients = useAppSelector(selectPatients).map(addIdToPatient)
  const requiredIncident = useAppSelector((s) => present(requiredIncidentId) && s.incidents.incidents.find((i) => i.id === requiredIncidentId))

  const hraCustomizations = useCustomization('hra')
  const canChoosePaidWithHra = hraCustomizations?.providesHraCard

  const defaultData = useMemo<Partial<NewExpenseFormModel>>(() => {
    return {
      date: format(Date.now(), 'yyyy-MM-dd'),
      patient_name: patients[0]?.full_name,
      patient_dob: patients[0]?.date_of_birth,
      incident_id: requiredIncidentId || undefined,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [row, setRow] = useState<Partial<NewExpenseFormModel>>({
    ...defaultData,
    ...prefilledData
  })
  
  /**
   * Overrides the default setter to also do automatic calculation & validation
   */
  const onBlur = () => {
    // Calculate the paid & owed amounts based on the new values if they haven't already been set.
    const resultSet = calculateRemainingAmounts(row)
    for (const [key, value] of entries(resultSet)) {
      if (!value) {
        continue
      }
      setRow((r) => ({...r, [key]: value.toFixed(2).replace(/\.00$/, '')}))
      clearValidationError(key as keyof ValidationErrors)
    }
    
    // Check whether the amounts all total up correctly
    if (row.listedAmount && row.discountAmount && row.paidAmount && row.remainingOwedAmount) {
      if (!new Decimal(row.listedAmount).sub(row.discountAmount).sub(row.paidAmount).eq(new Decimal(row.remainingOwedAmount))) {
        setValidationErrors((e) => ({...e, remainingOwedAmount: 'The amounts do not total up correctly'}))
      } else {
        setValidationErrors((e) => ({...e, remainingOwedAmount: undefined}))
      }
    }
  }
  
  const formRef = useRef<HTMLFormElement>(null)
  const [wasValidated, setWasValidated] = useState(false)
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})
  const clearValidationError = useCallback((field: keyof ValidationErrors) => {
    setValidationErrors((e) => ({...e, [field]: undefined}))
  }, [setValidationErrors])

  // Update the prefilled state if the prefilledData prop changes
  const prevPrefilledData = useRef<Partial<NewExpenseFormModel>>(row)
  useEffect(() => {
    if (!prefilledData) { return }
    if (isEqual(prefilledData, prevPrefilledData.current)) { return }

    setRow((r) => {
      const newRow = {...r}
      for (const [keyStr, value] of Object.entries(prefilledData)) {
        const key = keyStr as keyof typeof prefilledData

        // Change the form value only if the user hasn't set it
        // i.e. the current value is the default value
        if (typeof value != 'undefined' && value !== null && r[key] === defaultData[key]) {
          console.debug('setting', key, 'to', value, 'from', defaultData[key])
          newRow[key] = value as any
        }
      }
      return newRow
    })
    prevPrefilledData.current = prefilledData
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefilledData])

  const [selectedIncident, setSelectedIncident] = useState<IncidentInputGroupValue | null | undefined>(null)
  
  const currentIncidentId = selectedIncident?.id || row.incident_id
  const incidentIsMaternity = useAppSelector((s) => s.incidents.incidents.find((i) => i.id === currentIncidentId)?.is_maternity)

  const doInsert = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!validateRow(row, formRef, setValidationErrors)) {
      return
    }
    setWasValidated(true)
    

    let incidentId: string | null | undefined = requiredIncidentId || (selectedIncident && selectedIncident.id)
    let addIncidentAction: ReturnType<typeof addIncident> | undefined = undefined
    const now = new Date().toISOString()

    if (selectedIncident && !present(incidentId)) {
      const description = selectedIncident.description
      assert(description)

      incidentId = uuidv4()

      addIncidentAction = dispatch(addIncident({
        ...selectedIncident,
        id: incidentId,
        updated_at: now,
        created_at: now,

        membership_id: membershipId,
        created_by_user_id: userId,
        start_date: row.date,
        patient_dob: row.patient_dob,
        patient_name: row.patient_name,
        description,
      }))
    } else {
      const incident = requiredIncident || selectedIncident
      const incidentDate = incident && 'start_date' in incident && incident.start_date
      if (incidentDate && row.date < incidentDate) {
        // This expense is before the incident, so we need to update the incident's start date
        dispatch(updateIncident({
          id: incident.id,
          updated_at: now,
          start_date: row.date
        }))
      }
    }
    
    const isFullyPaid = new Decimal(row.remainingOwedAmount).eq(new Decimal(0))
    const postDiscountAmount = new Decimal(row.listedAmount).sub(row.discountAmount).toFixed(2)

    const expenseId = uuidv4()
    const addExpenseAction = dispatch(addExpense({
      id: expenseId,
      updated_at: now,
      created_at: now,
      membership_id: membershipId,
      created_by_user_id: userId,
      incident_id: incidentId || null,
      date: row.date,
      patient_dob: row.patient_dob,
      patient_name: row.patient_name,
      provider: row.provider,
      provider_id: row.provider_id,
      paid_with_hra: row.paid_with_hra,
      is_prepayment_agreement: row.is_prepayment_agreement,
      
      listedAmount: row.listedAmount,
      
      // Legacy naming issue: "paidAmount" is the amount after discounts
      paidAmount: postDiscountAmount,
      
      is_fully_paid: isFullyPaid,
      payment_history: {
        _version: '2024-12-24',
        payments: [{
          date: row.date,
          amount: row.paidAmount
        }]
      }
    }))

    if (onInserted) {
      onInserted(addExpenseAction.payload, addIncidentAction?.payload ?? null)
    }
  }

  const selectedPatient = present(row.patient_name) && present(row.patient_dob) &&
      patients.find((p) => p.full_name === row.patient_name && p.date_of_birth === row.patient_dob)

  return <form
      ref={formRef}
      className={`new-expense-row-form ${wasValidated && 'was-validated'}`}>
    <div className="input-group has-validation">
      <label className='input-group-text'>Date</label>
      <input name="date" placeholder="DateRange" type="date"
        className={`form-control ${validationErrors.date ? 'is-invalid' : ''}`}
        required
        disabled={disabledFields?.includes('date')}
        value={row.date || ''}
        onChange={(e) => { setRow({...row, date: e.target.value }) }}></input>
      {validationErrors.date && <div className="invalid-feedback show">{validationErrors.date}</div> }
    </div>
    <div className='input-group'>
      <label className='input-group-text'>Provider</label>
      <div className="form-control new-expense-row-form__provider-select-form-control">
        <ProviderSelect
          className={validationErrors.provider ? 'is-invalid' : ''}
          disabled={disabledFields?.includes('provider')}
          value={row.provider ? {
            value: row.provider,
            id: row.provider_id || undefined
          } : null}
          onChange={(newValue) => {
            setRow({
              ...row,
              provider: newValue?.value || null,
              provider_id: newValue?.id || null
            })
          }}
        />
      </div>
      {validationErrors.provider && 
        <div className="invalid-feedback show">{validationErrors.provider}</div>
      }
    </div>
    <div className='input-group has-validation'>
      <label className='input-group-text'>Patient</label>
      {patients && <PatientSelect className={`form-select ${validationErrors.patient_name || validationErrors.patient_dob ? 'is-invalid' : ''}`}
        required
        options={patients}
        value={selectedPatient}
        disabled={disabledFields?.includes('patient_name') && disabledFields?.includes('patient_dob') && present(selectedPatient)}
        onChange={({value}) => {
          if (!value) {
            setRow({
              ...row,
              patient_name: undefined,
              patient_dob: undefined,
              incident_id: undefined
            })
          } else if ('id' in value) {
            setRow({
              ...row,
              patient_name: value.full_name,
              patient_dob: value.date_of_birth,
              incident_id: null
            })
          }
        }} />}


      {validationErrors.patient_dob && <div className="invalid-feedback">{validationErrors.patient_dob}</div> }
      {validationErrors.patient_name && <div className="invalid-feedback">{validationErrors.patient_name}</div> }
    </div>
    
    <div className='input-group'>
      <label className='input-group-text'>
        <span className='d-none d-md-inline'>
          Pre-Discount Amount
        </span>
        <Tooltip  className='d-none d-md-inline ms-auto' tooltip="The listed amount before any discounts, such as the self-pay discount or financial aid" />
        <Tooltip className='d-inline d-md-none'
            tooltip="The listed amount before any discounts, such as the self-pay discount or financial aid">
          Pre-Discount
        </Tooltip>
          
      </label>
      <input name="listedAmount" type='text'
        className={`form-control ${validationErrors.listedAmount ? 'is-invalid' : ''}`}
        value={`$${row.listedAmount || ''}`}
        pattern='\$\d+(\.\d{0,2})?'
        disabled={disabledFields?.includes('listedAmount')}
        onBlur={onBlur}
        onChange={(e) => {
          const listedAmount = (e.target.value && parseDollarAmount(e.target.value)) || ''
          setRow({...row, listedAmount})
          clearValidationError('listedAmount')
        }}></input>

      {validationErrors.listedAmount && <div className="invalid-feedback show">{validationErrors.listedAmount}</div> }
    </div>
    
    <div className='input-group'>
      <label className='input-group-text'>
        <span className='d-none d-md-inline'>
         Discount Amount
        </span>
        <Tooltip  className='d-none d-md-inline ms-auto' tooltip="The total amount of discounts applied to this expense" />
        <Tooltip className='d-inline d-md-none'
            tooltip="The total amount of discounts applied to this expense">
          Discount
        </Tooltip>
          
      </label>
      <input name="discountAmount" type='text'
        className={`form-control ${validationErrors.discountAmount ? 'is-invalid' : ''}`}
        value={`$${row.discountAmount || ''}`}
        pattern='\$\d+(\.\d{0,2})?'
        disabled={disabledFields?.includes('discountAmount')}
        onBlur={onBlur}
        onChange={(e) => {
          const discountAmount = (e.target.value && parseDollarAmount(e.target.value)) || ''
          setRow({...row, discountAmount})
          clearValidationError('discountAmount')
        }}></input>

      {validationErrors.discountAmount && <div className="invalid-feedback show">{validationErrors.discountAmount}</div> }
    </div>
    
    <div className='input-group has-validation'>
      <label className='input-group-text'>
        <span className='d-none d-md-inline'>Amount You Already Paid</span>
        <Tooltip className='d-none d-md-inline ms-auto' tooltip="The total amount you have already paid for this expense.  If you have not paid anything yet, enter 0." />
        <Tooltip className='d-inline d-md-none'
            tooltip="The total amount you have already paid for this expense.  If you have not paid anything yet, enter 0.">
          Paid
        </Tooltip>
      </label>
      <input name="paidAmount" type='text'
        className={`form-control ${validationErrors.paidAmount ? 'is-invalid' : ''}`}
        value={`$${row.paidAmount || ''}`}
        pattern='\$\d+(\.\d{0,2})?'
        disabled={disabledFields?.includes('paidAmount')}
        onBlur={onBlur}
        onChange={(e) => {
          const paidAmount = (e.target.value && parseDollarAmount(e.target.value)) || ''
          setRow({...row, paidAmount})
          clearValidationError('paidAmount')
        }}></input>
      {validationErrors.paidAmount && <div className="invalid-feedback show">{validationErrors.paidAmount}</div> }
    </div>
    
    <div className='input-group has-validation'>
      <label className='input-group-text'>
        <span className='d-none d-md-inline'>Amount You Owe</span>
        <Tooltip className='d-none d-md-inline ms-auto' tooltip="The amount you still owe after discounts and payments.  If you have not paid anything yet, enter the full amount here." />
        <Tooltip className='d-inline d-md-none'
            tooltip="The amount you still owe after discounts and payments.  If you have not paid anything yet, enter the full amount here.">
          Owed
        </Tooltip>
      </label>
      <input name="remainingAmount" type='text'
        className={`form-control ${validationErrors.remainingOwedAmount ? 'is-invalid' : ''}`}
        value={`$${row.remainingOwedAmount || ''}`}
        pattern='\$\d+(\.\d{0,2})?'
        disabled={disabledFields?.includes('remainingOwedAmount')}
        onBlur={onBlur}
        onChange={(e) => {
          const remainingOwedAmount = (e.target.value && parseDollarAmount(e.target.value)) || ''
          setRow({...row, remainingOwedAmount})
          clearValidationError('remainingOwedAmount')
        }}></input>
      {validationErrors.remainingOwedAmount && <div className="invalid-feedback show">{validationErrors.remainingOwedAmount}</div> }
    </div>

    {canChoosePaidWithHra &&
      <div className='input-group'>
        <div className='new-expense-row-form__form-check-wrapper'>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio'
              name='paid_with' id='paid_with_hra' value='hra'
              checked={row.paid_with_hra === true}
              onChange={(e) => {
                if (e.target.checked) {
                  setRow({...row, paid_with_hra: true})
                }
              }}></input>
            <label className="form-check-label" htmlFor="paid_with_hra">{hraCustomizations.hraCardLabel || 'HRA card'}</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio'
              name='paid_with' id='paid_with_personal' value='personal'
              checked={row.paid_with_hra === false}
              onChange={(e) => {
                if (e.target.checked) {
                  setRow({...row, paid_with_hra: false})
                }
              }}></input>
            <label className="form-check-label" htmlFor="paid_with_personal">Personal</label>
          </div>
        </div>
      </div>}

    {incidentIsMaternity &&
    <div className="form-check mt-2">
      <input className="form-check-input" type="checkbox" id="is_pre_payment_agreement"
        checked={!!row.is_prepayment_agreement}
        onChange={(e) => {
          setRow({...row, is_prepayment_agreement: e.target.checked})
        }}
        />
      <label className="form-check-label" htmlFor="is_maternity">
          This is a Pre-Payment Agreement
      </label>
    </div>}

    <div className="col-12 mt-2">
      <IncidentInputGroup
        allowNewIncident={allowNewIncident}
        requiredIncidentId={requiredIncidentId}
        selectedPatient={selectedPatient}
        value={selectedIncident}
        onChange={setSelectedIncident} />
    </div>

    <div className="col-12 d-flex mt-2">
    <button type='submit'
        className={`btn btn-primary`}
        onClick={doInsert}>
        Add
    </button>
    </div>
  </form>
}

type CompleteExpenseFormModel = NotNull<NewExpenseFormModel, 'date' | 'patient_dob' | 'patient_name' | 'listedAmount'| 'discountAmount' | 'paidAmount' | 'remainingOwedAmount'>

function validateRow(row: Partial<NewExpenseFormModel>, formRef: React.RefObject<HTMLFormElement>, setValidationErrors: (errors: ValidationErrors) => void): row is CompleteExpenseFormModel {
  const validationErrors: ValidationErrors = {}
  let isInvalid = false
  
  if (!formRef?.current?.reportValidity()) {
    isInvalid = true
  }
  
  const listedAmount = tryParseDecimal(row.listedAmount)
  const discountAmount = tryParseDecimal(row.discountAmount)
  const paidAmount = tryParseDecimal(row.paidAmount)
  const remainingOwedAmount = tryParseDecimal(row.remainingOwedAmount)
  
  if (!row.patient_dob) { validationErrors.patient_dob = 'Please select a patient'; isInvalid = true;    }
  if (!row.patient_name) { validationErrors.patient_name = 'Please select a patient'; isInvalid = true;  }
  if (!row.date) { validationErrors.date = 'Please select a date'; isInvalid = true;  }

  if (!listedAmount) { validationErrors.listedAmount = 'Please enter a valid dollar amount'; isInvalid = true;  }
  if (listedAmount) {
    if (listedAmount.lessThan(0)) { validationErrors.listedAmount = 'Listed amount cannot be less than 0'; isInvalid = true;  }
  }
  if (!discountAmount) { validationErrors.discountAmount = 'Please enter a valid dollar amount'; isInvalid = true;  }
  if (discountAmount) {
    if (discountAmount.lessThan(0)) { validationErrors.discountAmount = 'Discount amount cannot be less than 0'; isInvalid = true;  }
  }
  if (!paidAmount) {validationErrors.paidAmount = 'Please enter a valid dollar amount'; isInvalid = true;  }
  if (paidAmount) {
    if (paidAmount.lessThan(0)) { validationErrors.paidAmount = 'Paid amount cannot be less than 0'; isInvalid = true;  }
  }
  if (!remainingOwedAmount) { validationErrors.remainingOwedAmount = 'Please enter a valid dollar amount'; isInvalid = true;    }
  if (remainingOwedAmount) {
    if (remainingOwedAmount.lessThan(0)) { validationErrors.remainingOwedAmount = 'Owed amount cannot be less than 0'; isInvalid = true;  }
  }
  
  // Check whether the amounts all total up correctly
  if (listedAmount && discountAmount && paidAmount && remainingOwedAmount) {
    if (!listedAmount.sub(discountAmount).sub(paidAmount).eq(remainingOwedAmount)) {
      validationErrors.remainingOwedAmount = 'The amounts do not total up correctly'
      isInvalid = true
    }
  }
  
  setValidationErrors(validationErrors)
  
  return !isInvalid
}

function tryParseDecimal(value: string | undefined): Decimal | undefined {
  if (!value) { return undefined }
  try {
    return new Decimal(value)
  } catch (e) {
    return undefined
  }
}
