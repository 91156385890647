import { PDFDocument } from "pdf-lib";

/**
 * This function takes a list of images and creates a PDF document from them.
 * The PDF will have a single page for each image.
 * 
 * @param images - The list of images to create a PDF from.
 * @returns A Blob containing the PDF document.
 */
export async function createPdfFromImages(images: File[]): Promise<Blob> {
  const pdf = await PDFDocument.create()

  // Create pages for each image
  for (const image of images) {
    // Read image data
    const imageBytes = await image.arrayBuffer()
    
    // Create a new page
    const page = pdf.addPage()
    
    // Embed the image
    let embeddedImage
    if (image.type === 'image/jpeg') {
      embeddedImage = await pdf.embedJpg(imageBytes)
    } else if (image.type === 'image/png') {
      embeddedImage = await pdf.embedPng(imageBytes)
    } else {
      throw new Error(`Unsupported image type: ${image.type}`)
    }

    // Calculate dimensions to fit image on page while maintaining aspect ratio
    const { width, height } = embeddedImage.scale(1)
    const pageWidth = page.getWidth()
    const pageHeight = page.getHeight()
    
    const scale = Math.min(
      pageWidth / width,
      pageHeight / height
    )

    const scaledWidth = width * scale
    const scaledHeight = height * scale

    // Center image on page
    const x = (pageWidth - scaledWidth) / 2
    const y = (pageHeight - scaledHeight) / 2

    // Draw the image
    page.drawImage(embeddedImage, {
      x,
      y,
      width: scaledWidth,
      height: scaledHeight
    })
  }

  // Save the PDF
  const pdfBytes = await pdf.save()

  // Convert to blob
  const blob = new Blob([pdfBytes], { type: 'application/pdf' })
  return blob
}
