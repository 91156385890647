import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Decimal from "decimal.js";
import { getCurrentLimits } from "../../lib/rules/limits";
import { formatCurrency } from "../../lib/formatCurrency";
import { IncidentModel } from "../reduxToolkit/incidentsSlice";
import { useAppSelector } from "../hooks/reduxToolkit";
import { selectDependentTodos } from "../reduxToolkit/selectors/todos";
import { selectToDosForIncident } from "../reduxToolkit/selectors/todos";
import { ToDoList } from "./todoList";
import { Tooltip } from "./tooltip";
import { isCompleteExpense, isSubmittedExpense } from "../reduxToolkit/expensesSlice";
import { useSentry } from "../providers/sentry";
import { usePendingSubmission } from "../hooks/usePendingSubmission";
import { useCustomization } from "../hooks/useCustomizations";

import './incidentSubmitButton.scss'

interface IncidentSubmitButtonProps {
  buttonType?: string

  incident: IncidentModel
}

export function IncidentSubmitButton({buttonType, incident}: IncidentSubmitButtonProps) {
  const navigate = useNavigate()
  const { captureException } = useSentry()
  
  const hraCustomizations = useCustomization('hra')
  const incidentExpenses =
    useAppSelector((state) =>
        state.expenses.expenses
        .filter((expense) => expense.incident_id === incident.id));
  const unsubmittedExpenses = incidentExpenses
        .filter((e) => !isSubmittedExpense(e))
  
  const [pendingOrUnsavedSubmission, ] = usePendingSubmission(incident)
  // an unsaved submission doesn't have an ID.  We only want to resume a pending submission.
  const pendingSubmissionId = pendingOrUnsavedSubmission?.id

  const todos = useAppSelector(selectToDosForIncident(incident.id))
  // find the submit todo
  const submitTodo = todos.find((todo) => todo.key === `incident/${incident.id}/submit`)

  const dependentTodos = useAppSelector(selectDependentTodos(submitTodo))

  const [show, setShow] = useState(false)
  
  const limits = getCurrentLimits()


  // If we don't have a TODO to submit this incident, or we've already done that, then we don't need to show this button
  if (!submitTodo) {
    const sumListed: Decimal = incidentExpenses.filter(isCompleteExpense)
      .reduce((sum, expense) => sum.plus(new Decimal(expense.listedAmount)), new Decimal(0))
    
    let wording: string = 'Nothing to Submit'
    // why can't I submit?
    let why: string | null = null
    if (unsubmittedExpenses.length === 0) {
      why = 'All your expenses for this incident have already been submitted.'
    } else if (unsubmittedExpenses.some((e) => !isCompleteExpense(e))) {
      why = 'Some of your expenses are missing data.  Please complete them before submitting.'
      wording = 'Cannot Submit'
    } else if (
      unsubmittedExpenses.every((e) => e.paid_with_hra === true)
    ) {
      why = 'All of your expenses have been automatically submitted to the HRA because you used your HRA card.  ' +
        `Once your expense total crosses over ${formatCurrency(limits.qualifying_amount)}, you can submit to CHM for reimbursement.`
    } else if (!hraCustomizations?.hasHra && sumListed.lte(limits.qualifying_amount)) {
      why = `The total value of your expenses is less than ${formatCurrency(limits.qualifying_amount)}, so you can't submit them for reimbursement yet.`
    } else {
      // This should not happen.  Report it to sentry, but don't blow up the user's screen.
      captureException(new Error(`Incident ${incident.id} has no submit todo, but has ${unsubmittedExpenses.length} pending expenses`),
        {
          extra: {
            todos,
            incidentId: incident.id,
          }
        })
    }

    return <div className="incident-submit-button">
      <Tooltip tooltip={why}>
        <button className={`btn btn-outline-secondary disabled incident-submit-button__toggle`} type="button">
          {wording}
        </button>
      </Tooltip>
    </div>
  }

  // If some of the expenses are not complete, then we can't submit yet
  const canSubmit = pendingSubmissionId || dependentTodos.length === 0

  // We can't skip if we have to "fill" some expenses
  const canSkip = !dependentTodos.some((todo) => todo.todo_type === 'fill')

  const _onClick = () => {
    if (pendingSubmissionId) {
      navigate(`/incidents/${incident.id}/submit/${pendingSubmissionId}`)
    } else if (canSubmit) {
      navigate(`/incidents/${incident.id}/submit`)
    } else {
      setShow(!show)
    }
  }

  let submitText = 'Submit for Reimbursement'
  if (pendingSubmissionId) {
    submitText = 'Continue Submission in Progress'
  } else {
    if (show) {
      submitText = 'Complete These Actions First'
    }
    if (show && canSubmit) {
      submitText = 'Ready to Submit'
    }
  }

  return <div className="incident-submit-button">
    <button className={`btn btn-${buttonType || 'primary'} incident-submit-button__toggle`} type="button"
        data-can-submit={canSubmit}
        aria-expanded={show}
        onClick={_onClick}>
      {submitText}
    </button>
    <div className={`collapse ${show && 'show'}`}>
      <ToDoList key={`incident/${incident.id}/todos`} todos={dependentTodos} />
      {canSkip &&
        <Link to={`/incidents/${incident.id}/submit`} className="btn btn-sm btn-link incident-submit-button__skip">
          <small>skip this and just give me my documents</small>
        </Link>}
    </div>
  </div>

}
