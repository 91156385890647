import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist';
import { DeepNullable, DeepPartialNullable, NotNull } from '../../types/util'
import { redactName, redactObjectExceptKeys } from '../../lib/util/redact'
import { replaceOrInsert } from '../../lib/util/replaceOrInsert';
import { onSyncDownComplete } from './actions/onSyncDownComplete';
import { NotDeleted, ProviderDataSource, ProviderUpdate } from '../../types/supabase';

export type ProviderModel = {
  id: string
  created_at: string
  updated_at: string
  deleted_at?: string | null
  membership_id: string
  
  name: string
  street_address?: string | null
  street_address_source?: ProviderDataSource | null
  billing_phone?: string | null
  billing_phone_source?: ProviderDataSource | null
  billing_email?: string | null
  billing_email_source?: ProviderDataSource | null
  website?: string | null
  website_source?: ProviderDataSource | null
  
  google_places_id?: string | null
}

export type ProvidersSliceState = {
  providers: ProviderModel[]
}

const initialState = {
  providers: []
} as unknown as ProvidersSliceState

export type ProviderInsertPayload = ProviderModel
export type ProviderUpdatePayload = NotNull<NotDeleted<ProviderUpdate>, 'id' | 'updated_at'>

export const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    addProvider(state, action: PayloadAction<ProviderInsertPayload>) {
      state.providers ||= []
  
      state.providers.push(action.payload)
    },
    updateProvider(state, action: PayloadAction<ProviderUpdatePayload>) {
      state.providers ||= []
      
      const i = state.providers.findIndex((t) => t.id === action.payload.id)
      if (i < 0) {
        throw new Error(`Could not find provider with id ${action.payload.id}`)
      }
      // Replace the record with the updated values
      state.providers[i] = {
        ...state.providers[i],
        ...action.payload,
      }
    },
    deleteProvider(state, action: PayloadAction<{ id: string, updated_at: string, deleted_at: string }>) {
      state.providers ||= []
      
      const i = state.providers.findIndex((t) => t.id === action.payload.id)
      if (i >= 0 && state.providers[i].updated_at <= action.payload.updated_at) {
        state.providers.splice(i, 1)
      }
    },
  },
  extraReducers: (builder) => {
    builder = builder.addCase(PURGE, (state) => {
      return initialState
    })
    builder = builder.addCase(onSyncDownComplete, (state, action) => {
      state.providers ||= []

      // Update any expenses or incidents that have been updated on the server
      for (const provider of action.payload.providers?.updated || []) {
        replaceOrInsert(state.providers, provider)
      }

      for (const deletedProvider of action.payload.providers?.deleted || []) {
        const i = state.providers.findIndex((t) => t.id === deletedProvider.id)
        if (i >= 0 && state.providers[i].updated_at <= deletedProvider.updated_at) {
          state.providers.splice(i, 1)
        }
      }
    })

    return builder
  }
})

// Action creators are generated for each case reducer function
export const { addProvider, updateProvider, deleteProvider } = providersSlice.actions

export default providersSlice.reducer

export type ProvidersSliceAction = ReturnType<typeof providersSlice.actions[keyof typeof providersSlice.actions]>

export function isProvidersSliceAction(action: any): action is ProvidersSliceAction {
  return action.type?.startsWith(providersSlice.name)
}

export function redactProvider(provider: Partial<ProviderModel>): DeepPartialNullable<ProviderModel> {
  return {
    ...redactObjectExceptKeys(provider,
      'id', 'created_at', 'updated_at', 'deleted_at', 'membership_id'),
    name: redactName(provider.name),
  }
}

export function redactProviders(state: ProvidersSliceState): DeepPartialNullable<ProvidersSliceState> {
  return {
    providers: state.providers?.map(redactProvider),
  }
}

export function redactProvidersSliceAction(action: ProvidersSliceAction) {
  switch(action.type) {
    case addProvider.type:
    case updateProvider.type:
    case deleteProvider.type:
      return {
        type: action.type,
        payload: redactProvider(action.payload)
      }

    default:
      return {
        type: (action as any).type,
        payload: 'REDACTED'
      }
  }
}

export function providerDataSourceLabel(source: ProviderDataSource | null) {
  switch(source) {
    case 'textract': return 'Parsed from receipt'
    case 'google_places': return 'Found on Google Maps'
    case 'manual': return null;
    default: return null;
  }
}
