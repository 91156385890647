import React, { useEffect } from 'react'
import * as ReactErrorBoundary from 'react-error-boundary'
import * as Sentry from "@sentry/react";
import { PostgrestError } from '@supabase/supabase-js';

const InnerErrorBoundary = ReactErrorBoundary.ErrorBoundary

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ErrorBoundaryProps {

}

export function ErrorBoundary({children}: React.PropsWithChildren<ErrorBoundaryProps>) {
  return <InnerErrorBoundary
      fallback={<ErrorFallback />}
      onError={(error: unknown) => {
        console.error('ErrorBoundary', error)
        let err: Error;
        if ((error instanceof Error)) {
          err = error;
        } else {
          if (isPostgrestError(error)) {
            err = new Error(error.message, {
              cause: error,
            });
          } else {
            err = new Error(JSON.stringify(error), {
              cause: error,
            });
          }
        }
        
        const eventId = Sentry.captureException(err)
        Sentry.showReportDialog({ eventId })
      }} >
    {children}
  </InnerErrorBoundary>
}

export function ErrorFallback() {
  return <div className="container">
    <div className='row'>
      <div className='col col-lg-6 offset-lg-3'>
        <div className="error-fallback-screen">
          <h1>Something went wrong!</h1>

          <p>
            We're sorry, something went wrong.  We've already been notified of the problem.  If you continue to see
            errors, please reach out at <a href="mailto:gordon@healthsharetech.com">gordon@healthsharetech.com</a>
          </p>

          <button className="btn btn-primary"
              onClick={() => window.location.href = '/'}>
            Reload your browser
          </button>
        </div>
      </div>
    </div>
  </div>
}


function isPostgrestError(error: any): error is PostgrestError {
  return error && typeof error === 'object' && 'message' in error && 'code' in error;
}
