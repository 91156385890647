import { RootState } from "../store"

export interface Customizations {
  hra?: HraCustomizations,
  
  personalResponsibility?: PersonalResponsibilityCustomizations
}

export type HraCustomizations = {
  hasHra?: false | undefined,
  hraUrl?: undefined,
  providesHraCard?: false | undefined,
  hraCardLabel?: undefined
} | {
  /**
   * Whether the organization provides an HRA for the member
   */
  hasHra: true,
  /**
   * The URL for submitting an HRA
   */
  hraUrl: string,
  
  /**
   * Whether the organization provides an HRA card
   */
  providesHraCard: boolean
  
  /**
   * If they refer to the card as something else like "Blue Card", this can be used to customize the label
   */
  hraCardLabel?: string
}

export type PersonalResponsibilityCustomizations = {
  reimbursesChmPersonalResponsibility?: false | undefined
  reimbursementUrl?: undefined
  maternalPersonalResponsibilityReimbursedAmount?: undefined
} | {
  /**
   * Whether the organization reimburses the member for their CHM personal responsibility
   */
  reimbursesChmPersonalResponsibility: true
  /**
   * The URL to show in the checklist
   */
  reimbursementUrl: string

  /**
   * The maximum amount of maternity personal responsibility that is actually reimbursed by the organization
   */
  maternalPersonalResponsibilityReimbursedAmount?: number
}

export const DefaultCustomizations: Customizations = {
  hra: {
    hasHra: false,
  },
  
  personalResponsibility: {
    reimbursesChmPersonalResponsibility: false
  }
}

export function selectCustomizations(s: RootState): Customizations {
  const membershipCustomization = (s.membership?.organization?.customizations as unknown as Partial<Customizations>) ?? {}
  return {
    ...DefaultCustomizations,
    ...membershipCustomization
  }
}

const WatermarkCommunityChurch: Customizations = {
  hra: {
    hasHra: true,
    hraUrl: 'https://egpbpppt.lh1ondemand.com/AccountsActivity/ReimburseMyselfNonHsaOnly',
    providesHraCard: true
  },

  personalResponsibility: {
    reimbursesChmPersonalResponsibility: true,
    reimbursementUrl: 'https://egpbpppt.lh1ondemand.com/AccountsActivity/ReimburseMyselfNonHsaOnly'
  }
}
