import Decimal from "decimal.js";
import { Fragment, useMemo } from "react";
import { formatCurrency } from "../../lib/formatCurrency";


import './currencyLedger.scss'

interface CurrencyLedgerProps {
  className?: string | null | undefined;
  rows: {
    description: string;
    amounts?: Array<Decimal | null | undefined>;
    rowClass?: string | null | undefined;
  }[];
  
  columns?: Array<{
    title?: string | null | undefined;
    columnClass?: string | null | undefined;
  } | null | undefined>
}

export function CurrencyLedger({ className, rows, columns }: CurrencyLedgerProps) {
  
  const paddedFormattedRows = useMemo(() => {
    let maxDigitsBeforeDecimal = 0;
    let hasDecimal = false;
    const cleanFormattedRows = rows.map((row, index) => {
      if (!row.amounts || row.amounts.length == 0) {
        return undefined;
      }
      
      return row.amounts.map((amount) => {
        if (!amount) {
          return undefined
        }
        const formattedAmount = formatCurrency(amount);
        const cleanAmount = formattedAmount.startsWith('$') ? formattedAmount.substring(1) : formattedAmount;
        const [before,] = cleanAmount.split('.')
        maxDigitsBeforeDecimal = Math.max(maxDigitsBeforeDecimal, before.length)
        hasDecimal = hasDecimal || cleanAmount.includes('.')
        
        return cleanAmount;
      });
    });
    
    // Add padding to the left of the amount to align the decimal point
    return cleanFormattedRows.map((formattedValues, index) => {
      if (!formattedValues || formattedValues.length == 0) { return }
      
      return formattedValues.map((formattedAmount, index) => {
        if (!formattedAmount) { return }
        let [before, ...afterParts] = formattedAmount.split('.')
        
        const padding = ' '.repeat(maxDigitsBeforeDecimal - before.length);
        before = padding + before;
        let after = afterParts.join('.')
        if (hasDecimal && after.length == 0) {
          after = '00'
        }
        let result = before
        if (hasDecimal) {
            result += '.' + after
          }
          return result
        });
      });
  }, [rows]);

  return <table className={`currency-ledger ${className}`}>
    {columns && <thead>
      <tr>
        {columns.map((column, index) => (
          <th key={index} className={`currency-ledger__column-title ${column?.columnClass}`}>{column?.title}</th>
        ))}
      </tr>
    </thead>}
    <tbody>
      {rows.map((row, index) => (
        <tr key={index} className={`currency-ledger__row ${row.rowClass}`}>
          <td className={`currency-ledger__row-description ${columns?.[0]?.columnClass}`}>{row.description}</td>
          
          {paddedFormattedRows[index] && paddedFormattedRows[index]?.map((amount, index) => {
            if (!amount) { return }
            
            return <td key={index} className={`currency-ledger__row-amount ${columns?.[index + 1]?.columnClass}`}>
              <span className="currency-ledger__row-currency">$&nbsp;</span>
              <span className="currency-ledger__row-value">{amount}</span>
            </td>
          })}
        </tr>
      ))}
    </tbody>
  </table>
}
