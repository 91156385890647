/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from "../hooks/reduxToolkit"
import { ExpenseModel, deleteExpense, isCompleteExceptForIncidentID, isCompleteExpense, isTextractJobFailed, isTextractPending } from "../reduxToolkit/expensesSlice"
import { formatDateInTimeZone } from "../../lib/formatDateInTimeZone"
import { Attachment } from "./attachment"
import { present } from "../../lib/util/present"
import { Tooltip } from "./tooltip"
import { useCheckDocumentAnalysis } from "../hooks/useCheckDocumentAnalysis"
import { formatCurrency } from "../../lib/formatCurrency"
import { useCustomization } from "../hooks/useCustomizations"
import { Decimal } from "decimal.js"
import { CurrencyLedger } from "./currencyLedger"
import { maybeCalculateActualPaidAmountFromIncompleteExpense, maybeCalculateDiscountAmountFromIncompleteExpense, maybeCalculateRemainingOwedAmountFromIncompleteExpense } from "../../lib/expenses/amounts"

import './expenseTable.scss'

const seconds = 1000

interface ExpenseDisplayOptions {
  hideIncident?: boolean
  hideProvider?: boolean

  highlightExpenseId?: string | null
  
  expandAll?: boolean
}

interface ExpenseTableProps {
  expenses: ExpenseModel[]

  displayOptions?: ExpenseDisplayOptions
  
  disableEdit?: boolean
}

export function ExpenseTable({
  expenses,
  displayOptions,
  disableEdit
}: ExpenseTableProps) {
  const highlightedExpense = expenses.find((e) => e.id === displayOptions?.highlightExpenseId)

  return <div className="expense-table">
    {highlightedExpense &&
      <ExpenseRow expense={highlightedExpense}
        displayOptions={displayOptions}
        disableEdit={disableEdit} />}

    {expenses.slice().sort(byDateNullsFirst).map((expense) => {
      if (displayOptions?.highlightExpenseId === expense.id) {
        return null
      }

      return <ExpenseRow key={expense.id} expense={expense} displayOptions={displayOptions} disableEdit={disableEdit} />
    })}
  </div>
}

interface ExpenseRowProps {
  expense: ExpenseModel,

  displayOptions?: ExpenseDisplayOptions
  disableEdit?: boolean
}

function ExpenseRow({
  expense,
  displayOptions,
  disableEdit
}: ExpenseRowProps) {
  const {id, date, paidAmount, provider, submitted_at} = expense
  const { highlightExpenseId } = displayOptions || {}
  const {hraCardLabel} = useCustomization('hra') || {}

  const [expanded, setExpanded] = useState(displayOptions?.expandAll || highlightExpenseId === id)

  const isIncomplete = !isCompleteExpense(expense)
  const textractPending = isTextractPending(expense)
  const textractFailed = isTextractJobFailed(expense)

  const classes = [
    `expense-table__row col-12 card`,
    highlightExpenseId === id && 'highlighted',
    submitted_at && 'submitted',
    expanded && 'expanded',
    textractPending && !textractFailed && 'scanning',
    isIncomplete && !textractPending && !textractFailed && 'incomplete'
  ].filter(present)
  
  let alert: JSX.Element | undefined
  if (textractPending && !textractFailed) {
    alert = <Tooltip tooltip="Scanning expense to extract information.  This can sometimes take a few minutes.  You can close this and come back later or click the edit button to input information manually.">
      <i className="material-icons flicker">sensors</i>
    </Tooltip>
  } else if (isIncomplete && Date.parse(expense.created_at) < (Date.now() - 10 * seconds)) {
    alert = <Tooltip tooltip={textractFailed ? "Unable to extract expense information" : "This expense is missing some information"}>
      <i className="material-icons text-danger">warning</i>
    </Tooltip>
  }
  
  // By default, show the incident above the line and the provider below the line.
  // UNLESS we are hiding the incident, in which case we show the provider above the line.
  let showIncidentAboveTheLine = true
  let showProviderAboveTheLine = false
  if (displayOptions?.hideIncident) {
    showProviderAboveTheLine = true
    showIncidentAboveTheLine = false
  }

  return <div id={`expense-table-row_${expense.id}`} className="row">
    <div className={classes.join(' ')}>
      <div className="card-body">
        <div  className={`expense-table__row-header ${expanded && 'expanded'}`} onClick={!expanded ? ((e) => {e.preventDefault(); e.stopPropagation(); setExpanded(true) }) : undefined}>
          <div className="card-title">
            <h5>
              {date ? formatDateInTimeZone(date, { format: 'MM/dd/yyyy' }) : 'Unknown Date'}
              &nbsp;-&nbsp;
              {paidAmount ? formatCurrency(paidAmount) : 'Unknown Amount'}
            </h5>
            
            <div className="expense-table-row__badges">
              {expense.paid_with_hra &&
                <Tooltip tooltip="This expense was paid for with your HRA card.  It will only be included if this incident gets submitted to CHM.">
                  <span className="badge bg-secondary">{hraCardLabel || 'HRA CARD'}</span>
                </Tooltip>}
              
              {alert}
            </div>
          </div>
          
          {!displayOptions?.hideProvider && showProviderAboveTheLine && <ProviderLineItem expense={expense} />}
          {!displayOptions?.hideIncident && showIncidentAboveTheLine && present(expense.incident_id) && <IncidentLineItem expense={expense} />}

          <button className="btn btn-link expense-table__row-collapse-toggle"
              aria-expanded={expanded} aria-controls={`#expense-table-${id}`}
              onClick={(e) => {e.preventDefault(); e.stopPropagation(); setExpanded(!expanded) }}
              >
              <i className={`material-icons`}>{expanded ? 'expand_less' : 'expand_more'}</i>
          </button>
        </div>

        <div className={`expense-table__row-collapse collapse ${expanded && 'show'} card-text`} id={`expense-table-${id}`}
            data-id={id}>
          <ExpenseWidget expense={expense}
            displayOptions={displayOptions}
            expanded={expanded} 
            disableEdit={disableEdit}
            showIncidentAboveTheLine={showIncidentAboveTheLine}
            showProviderAboveTheLine={showProviderAboveTheLine} />
        </div>
      </div>
    </div>
  </div>
}

interface ExpenseWidgetProps {
  expense: ExpenseModel

  displayOptions?: ExpenseDisplayOptions
  expanded?: boolean
  disableEdit?: boolean
  showIncidentAboveTheLine?: boolean
  showProviderAboveTheLine?: boolean
}

function ExpenseWidget({
  expense,
  displayOptions,
  expanded,
  disableEdit,
  showIncidentAboveTheLine,
  showProviderAboveTheLine
}: ExpenseWidgetProps) {
  const {id, listedAmount, paidAmount: postDiscountAmount, patient_name, payment_history, is_fully_paid} = expense

  const dispatch = useAppDispatch()
  const attachments = useAppSelector((s) => s.attachments.attachments?.filter((a) => a.record_id === expense.id) || [])

  const [loadAttachments, setLoadAttachments] = useState(expanded)
  useEffect(() => {
    if (expanded) {
      setLoadAttachments(true)
    }
  }, [expanded])

  useCheckDocumentAnalysis(id)

  const deleteExpenseWithConfirm = useCallback((e: React.MouseEvent) => {
    e.preventDefault(); e.stopPropagation();

    if (window.confirm('Are you sure you want to delete this expense?')) {
      const now = new Date().toISOString()
      dispatch(deleteExpense({ id, updated_at: now, deleted_at: now }))
    }
  }, [dispatch, id])

  const isIncomplete = !isCompleteExpense(expense)

  let alerts: JSX.Element[] = []
  if (isIncomplete && !isTextractPending(expense) && Date.parse(expense.created_at) < (Date.now() - 10 * seconds)) {
    if (disableEdit) {
      alerts.push(
        <Tooltip tooltip={'This expense cannot be edited'}>
          {isCompleteExceptForIncidentID(expense) ?
            'This expense is not assigned to an incident.' :
            'Unable to automatically extract all expense information!'}
        </Tooltip>
      )
    } else {
      alerts.push(
        <Link to={`/expenses/${expense.id}/edit`}
          className={`card-link btn btn-outline-info`}
          state={{
            validateOnMount: true
          }}>
        {isCompleteExceptForIncidentID(expense) ?
          'This expense is not assigned to an incident.  Click here to fix that.' :
          'Unable to automatically extract all expense information!  Click here to fix that.'}
        </Link>
      ) 
    }
  }
  
  const remainingOwedAmount = maybeCalculateRemainingOwedAmountFromIncompleteExpense(expense)

  return <div className="expense-table__widget">
    {alerts}

    {!displayOptions?.hideIncident && present(expense.incident_id) && !showIncidentAboveTheLine && <div className="row">
      <div className="col">
        <IncidentLineItem expense={expense} />
      </div>
    </div>}
    {!displayOptions?.hideProvider && present(expense.provider_id) && !showProviderAboveTheLine && <div className="row">
      <div className="col">
        <ProviderLineItem expense={expense} />
      </div>
    </div>}

    <div className="row mt-4">
      <div className="col-6 col-md-4">
        <h6 className="expense-table__widget__details__title">Details</h6>
        <CurrencyLedger
          className="expense-table__widget__details"
          rows={[
          { description: 'Listed', amounts: listedAmount ? [new Decimal(listedAmount)] : undefined, rowClass: 'expense-table__widget__details__listed' },
          { description: 'Discount', amounts: [maybeCalculateDiscountAmountFromIncompleteExpense(expense)], rowClass: 'expense-table__widget__details__discount' },
          { description: 'Paid', amounts: [maybeCalculateActualPaidAmountFromIncompleteExpense(expense)], rowClass: 'expense-table__widget__details__paid' },
          {
            description: 'Owed',
            amounts: remainingOwedAmount ? [remainingOwedAmount] : undefined,
            rowClass: `expense-table__widget__details__owed ${remainingOwedAmount && !remainingOwedAmount.eq(0) ? 'expense-table__widget__details__owed--nonzero' : ''}`
          }
        ]} />
        
      </div>
      <div className="col-6 col-md-8 expense-table__widget__attachments">
        <h6 className="expense-table__widget__attachments__title">Attachments</h6>
        <div className="expense-table__widget__attachments__list">
          {
            loadAttachments && attachments.map((attachment) => {
              return <Attachment key={attachment.id} attachment={attachment} />
            })
          }
        </div>
      </div>
    </div>
    {!disableEdit && <div className="expense-table__widget__actions">
      <Link to={`/expenses/${expense.id}/edit`}
          className={`card-link btn btn-outline-info ${disableEdit ? 'disabled' : ''}`}
          onClick={disableEdit ? (e) => {e.preventDefault(); e.stopPropagation()} : undefined}>
        Edit
      </Link>
      &nbsp;
      <button className="btn btn-outline-danger" onClick={deleteExpenseWithConfirm}>Delete</button>
    </div>}
  </div>
}

function ProviderLineItem({ expense }: { expense: ExpenseModel }) {
  if (!present(expense.provider)) {
    return null
  }
  

  return <Link to={expense.provider_id ? `/providers/${expense.provider_id}` : `/expenses/${expense.id}/providers/new`}
          className="card-subtitle expense-table__row-provider">
      <h5 className="overline">Provider:</h5>
      <span className="d-flex w-100">
        {expense.provider}
        <i className="material-icons ms-auto expense-table__widget-incident__icon">arrow_right_alt</i>
      </span>
    </Link>
}

function IncidentLineItem({ expense }: { expense: ExpenseModel }) {
  const incident = useAppSelector((s) => s.incidents.incidents.find((i) => i.id === expense.incident_id))
  
  return <Link to={`/incidents/${incident?.id}`} className="expense-table__widget-incident">
      <h5 className="overline">Incident</h5>
      <span className="d-flex w-100">
      {incident?.description}
      <i className="material-icons ms-auto expense-table__widget-incident__icon">arrow_right_alt</i>
      </span>
    </Link>
}

function byDateNullsFirst(a: { date?: string | null }, b: { date?: string | null }) {
  if (!present(a.date)) {
    return -1
  }
  if (!present(b.date)) {
    return 1
  }
  
  return Date.parse(b.date) - Date.parse(a.date)
}



