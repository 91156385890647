import { useMemo, useState } from "react";
import { differenceInDays, endOfYear, startOfDay, startOfYear } from "date-fns";
import { byStartDateDescending } from "../../lib/util/sort";
import { useAppSelector } from "../hooks/reduxToolkit";
import { useIncidentSearch } from "../hooks/useIncidentSearch";
import { formatDateInTimeZone } from "../../lib/formatDateInTimeZone";
import { selectFirstTodoReferencingIncident } from "../reduxToolkit/selectors/selectFirstTodoReferencingIncident";
import { formatCurrency } from "../../lib/formatCurrency";
import { present } from "../../lib/util/present";
import { Tooltip } from "../components/tooltip";
import { Link, useNavigate } from "react-router-dom";
import { IncidentWithExpensesAndSubmissions, selectIncidentsWithExpensesAndSubmissions } from "../reduxToolkit/selectors/selectIncidentsWithExpensesAndSubmissions";
import { isOpenIncident } from "../reduxToolkit/selectors/selectOpenIncidents";

import './incidents.scss'

export function IncidentsIndex() {
  const allIncidents: IncidentWithExpensesAndSubmissions[] = useAppSelector(selectIncidentsWithExpensesAndSubmissions);
  const sortedIncidents = allIncidents.sort(byStartDateDescending);
  
  const [results, search, {query}] = useIncidentSearch(sortedIncidents);
  
  const [showAllIncidents, setShowAllIncidents] = useState(false)

  let incidentsToShow: IncidentWithExpensesAndSubmissions[] = [...sortedIncidents]
  if (results) {
    incidentsToShow = results
  }
  if (!showAllIncidents) {
    incidentsToShow = incidentsToShow.filter(isOpenIncident)
  }

  let headerText = 'Unsubmitted Expenses'
  let bodyText: React.ReactNode | null = null;
  if (query) {
    headerText = 'Search Results'
    if (incidentsToShow.length === 0) {
      if (showAllIncidents) {
        bodyText = <em>No incidents found.</em>
      } else {
        bodyText = <div>
          <em>No open incidents found.</em><br/>
          <button className="btn btn-link" onClick={() => setShowAllIncidents(true)}>Search all incidents</button>
        </div>
      }
    }
  } else if (showAllIncidents) {
    headerText = 'All Incidents'
    if (incidentsToShow.length === 0) {
      bodyText = <em>You have no incidents.</em>
    }
  } else {
    headerText = 'Open Incidents'
    if (incidentsToShow.length === 0) {
      bodyText = <em>
        You have no open incidents.<br/>
        <button className="btn btn-link" onClick={() => setShowAllIncidents(true)}>View all incidents</button>
      </em>
    }
  }

  return <div className="incidents">
    <div className="row incidents__title">
      <div className="col-12">
        <h1>Incidents Search</h1>
        <p>
          View and search all your incidents.
        </p>
        
      </div>
      
      <div className='col-12 col-lg-6'>
        <label className='form-label me-auto'><strong>Search Incidents</strong></label>
        <input className="form-control" type="search" placeholder="date, provider, amount, etc." aria-label="Search"
          value={query || ''}
          onChange={(e) => {
            e.preventDefault();
            search(e.target.value)
          }} />
      </div>
      
      <div className="col-12 col-lg-6 mt-2">
        <Link to="/incidents/new" className="btn btn-sm btn-info float-end">
          + Create Incident 
        </Link>
      </div>
      
      <hr className="mt-4" />
      
      <div className="col-12">
        {incidentsToShow.length > 0 &&
          <>
            <h4>{headerText}</h4>
            {showAllIncidents ?
              <button className="btn btn-link" onClick={() => {
                setShowAllIncidents(false)
              }}>
                Show Open Incidents Only
              </button> :
              <button className="btn btn-link" onClick={() => {
                setShowAllIncidents(true)
                search('')
              }}>
                Show All Incidents
              </button>
            }
            <div className="incident-table">
              {incidentsToShow.map((i) =>
                <IncidentRow key={i.id} incident={i} />)}
            </div>
          </>}
          
        {bodyText}
      </div>
    </div>
  </div>
}

interface IncidentRowProps {
  incident: IncidentWithExpensesAndSubmissions,
}

function IncidentRow({
  incident
}: IncidentRowProps) {
  const {id, start_date, description} = incident
  
  const navigate = useNavigate();

  const today = useMemo(() => startOfDay(new Date(Date.now())), [])
  const eoy = useMemo(() => endOfYear(new Date(Date.now())), [])
  const boy = useMemo(() => startOfYear(new Date(Date.now())), [])
  
  const showYearInStartDate = start_date && new Date(start_date) < boy
  
  const firstTodo = useAppSelector(selectFirstTodoReferencingIncident(incident.id))

  const dueDate = firstTodo?.dueDate ? startOfDay(new Date(firstTodo.dueDate)) : null;
  const isPast = dueDate && dueDate < today
  const dueSoon = !dueDate || differenceInDays(dueDate, new Date()) <= 14
  const showYearInTodo = dueDate && dueDate > eoy
  
  const totalPaid = incident.expenses.reduce((sum, e) => sum + (parseFloat(e.paidAmount || '0')), 0)

  const classes = [
    `incident-table__row col-12 card`,
    isOpenIncident(incident) ? 'incident-table__row--open' : 'incident-table__row--closed',
  ].filter(present)

  return <div id={`incident-table-row_${incident.id}`} className="row">
    <div className={classes.join(' ')}>
      <div className="card-body">
        <div  className={`incident-table__row-header`} onClick={() => navigate(`/incidents/${incident.id}`)}>
          <div className="card-title">
            <h5>
              {start_date ? formatDateInTimeZone(start_date, { format: showYearInStartDate ? 'MMM dd, yyyy' : 'MMM dd' }) : 'Unknown Date'}
              &nbsp;-&nbsp;
              {totalPaid ?
                formatCurrency(totalPaid) :
                incident.expenses.length > 0 ?
                  'Unknown Amount' :
                  'No Expenses Yet'
              }
            </h5>
            
            <div className="incident-table-row__badges">
              {firstTodo &&
                <Tooltip tooltip="There are one or more items you need to check off your list related to this incident.">
                  <span className={`badge ${dueSoon ? 'bg-danger' : 'bg-secondary'}`}>
                    <span className="d-none d-md-inline">due&nbsp;</span>{
                    (isPast || !dueDate) ?
                      'ASAP' :
                      formatDateInTimeZone(dueDate, { format: showYearInTodo ? 'MMM dd, yyyy' : 'MMM dd' })
                    }
                  </span>
                </Tooltip>}
            </div>
          </div>
          <div className="card-subtitle">
            {description}
          </div>
        </div>
      </div>
    </div>
  </div>
}
