import { useState } from "react";
import { ExpenseTable } from "../components/expenseTable";
import { NewExpenseFormButton } from '../components/newExpenseFormButton';
import { useAppSelector } from "../hooks/reduxToolkit";
import { ExpensesNavigation } from '../components/expensesNavigation';
import { useExpenseSearch } from "../hooks/useExpenseSearch";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { present } from "../../lib/util/present";
import { ExpenseModel } from "../reduxToolkit/expensesSlice";
import { useNavigateToNewExpense } from "../hooks/useNavigateToExpense";

import './expenses.scss'

export function Expenses() {
  const location = useLocation()
  const navigateToNewExpense = useNavigateToNewExpense()

  const expenses = useAppSelector((state) => state.expenses.expenses)

  const [highlightExpenseId, setHighlightExpenseId] = useState<string | null>(location.state?.expense_id || null)
  const [showAllExpenses, setShowAllExpenses] = useState(false)

  const [
    results,
    search,
    { query }
  ] = useExpenseSearch(expenses)

  const pendingExpenses = expenses.filter((e) => !present(e.submitted_at))

  let headerText = 'Unsubmitted Expenses'
  if (results) {
    headerText = 'Search Results'
  } else if (showAllExpenses) {
    headerText = 'All Expenses'
  } else if (pendingExpenses.length === 0) {
    headerText = 'All Expenses Submitted'
  }

  let expensestoShow: ExpenseModel[]
  if (results) {
    expensestoShow = results
  } else if (showAllExpenses) {
    expensestoShow = expenses
  } else {
    expensestoShow = pendingExpenses
  }

  return <div className="expenses">
  <div className="row expenses__title">
    <div className="col-12">
      <h1>Expenses Search</h1>
      <p>
        View and search all your unsubmitted expenses.
      </p>

      <ExpensesNavigation fuseSearch={{
            query,
            search: (newQuery) => {
              setHighlightExpenseId(null)
              search(newQuery)
            }
          }} />
    </div>
  </div>
  <hr />
  <div className="row expenses__row">
    <div className="col-12">
      <div className="expenses__row-header">
        <div>
          <h6>
            {headerText}
          </h6>

          <button className="btn btn-sm btn-link" onClick={() => setShowAllExpenses(!showAllExpenses)}>
            {showAllExpenses ?
               'Show Unsubmitted Expenses Only' :
               'Show All Expenses'}
          </button>
        </div>

        <div>
          <NewExpenseFormButton allowNewIncident
            onInserted={navigateToNewExpense} />
          <Link to={`/bulkAddExpense`}
              className="btn btn-sm btn-link expenses__add__bulk">
            add multiple expenses
          </Link>
        </div>
      </div>
      <ExpenseTable expenses={expensestoShow}
        displayOptions={{
          highlightExpenseId,
        }}/>
    </div>
  </div>
  </div>
}
