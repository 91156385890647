import { createContext, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { PushImplementation, NotificationPermission } from "../../lib/push/interface";
import { SplashScreen } from "../screens/splashScreen";
import { supabase } from "./supabase";
import { isDisposable } from "../../lib/util/isDisposable";

declare global {
  interface Window {
    webkit: any;
  }
}

const iosPushCapability = 
  window.webkit &&
  window.webkit.messageHandlers &&
  window.webkit.messageHandlers['push-permission-request'] &&
  window.webkit.messageHandlers['push-permission-state'];
  
const webPushCapability = 'Notification' in window && 'serviceWorker' in navigator;

export function PushNotificationsProvider({ children }: React.PropsWithChildren) {
  const [loading, setLoading] = useState(false);
  const [pushImplementation, setPushImplementation] = useState<PushImplementation>(new NoopPushImplementation());
  
  useEffect(() => {
    if (iosPushCapability) {
      console.log('has iosPushCapability');
      import('../../lib/push/iosPushImplementation')
        .then(({ IosPushImplementation }) => {
          const impl = new IosPushImplementation(supabase);
          impl.initialize();
          setPushImplementation(impl);
          setLoading(false);
          console.log('initialized ios push implementation');
        }).catch((e) => {
          console.error('Error initializing iOS push implementation', e);
          Sentry.captureException(e);
          setLoading(false);
        });
    } else if (webPushCapability) {
      console.log('has webPushCapability');
      import('../../lib/push/webPushImplementation')
        .then(({ WebPushImplementation }) => {
          const impl = new WebPushImplementation(supabase);
          impl.initialize();
          setPushImplementation(impl);
          setLoading(false);
          console.log('initialized web push implementation');
        }).catch((e) => {
          console.error('Error initializing web push implementation', e);
          Sentry.captureException(e);
          setLoading(false);
        });
    } else {
      console.log('no push capability');
      setLoading(false);
    }
    
    return () => {
      if (isDisposable(pushImplementation)) {
        pushImplementation[Symbol.dispose]();
      }
    }
    
  }, []);
  
  if (loading) {
    return <SplashScreen />
  }
  
  return <PushNotificationsContext.Provider value={pushImplementation}>
    {children}
  </PushNotificationsContext.Provider>
}

class NoopPushImplementation implements PushImplementation {
  async register() {
    return 'not-supported' as NotificationPermission;
  }
  
  getPermissionState() {
    return 'not-supported' as NotificationPermission;
  }
  
  setBadgeCount() {}
  
  clearBadgeCount() {}
}

const PushNotificationsContext = createContext<PushImplementation>(new NoopPushImplementation());

export function usePushNotifications() {
  return useContext(PushNotificationsContext);
}

