import { useRef, useState, useEffect } from "react"
import { BlobRow } from "../../types/supabase"
import { useClient } from "../providers/supabase"
import isEqual from "lodash/isEqual"
import uniq from "lodash/uniq"
import { uniqBy } from "lodash"

export function useLoadBlobs(keys?: string[]) {
  const client = useClient()

  if (keys) {
    keys = uniq(keys).sort()
  }

  // If the array changes, but the values in the array don't change,
  // no need to re-fetch
  const memoizedKeys = useRef(keys)

  const [blobs, setBlobs] = useState<BlobRow[]>()

  const [loading, setLoading] = useState(true)
  const [refreshing, setRefreshing] = useState(false)
  const [error, setError] = useState<Error>()

  useEffect(() => {
    if (!keys || keys.length === 0) {
      setLoading(false)
      setBlobs([])
      return
    }

    // If we've loaded once and the keys haven't changed, don't re-fetch
    if (blobs && isEqual(keys, memoizedKeys.current)) {
      return
    }

    const doReload = async () => {
      let query = client.from('blobs')
        .select('*')

      if (keys) {
        query = query.in('key', keys)
      }

      const resp = await query

      if (resp.error) { throw resp.error }
      setBlobs(
        uniqBy(resp.data, 'key')
      )
    }

    setRefreshing(true)
    doReload()
      .then(() => {
        setLoading(false)
        setRefreshing(false)
        setError(undefined)
        memoizedKeys.current = keys
      }, (err) => {
        setLoading(false)
        setRefreshing(false)
        setError(err)
        memoizedKeys.current = keys
      })

  }, [keys?.join(',')])

  return [
    blobs,
    { loading, refreshing, error }
  ] as const
}
