import { Decimal } from "decimal.js";
import { CompleteExpense, ExpenseModel } from "../../app/reduxToolkit/expensesSlice";
import { isNotNull } from "../../types/util";

export function calculateDiscountAmount(expense: Pick<CompleteExpense, 'listedAmount' | 'paidAmount'>): Decimal {
  return new Decimal(expense.listedAmount).minus(new Decimal(expense.paidAmount))
}

export function maybeCalculateDiscountAmountFromIncompleteExpense(expense: Pick<ExpenseModel, 'listedAmount' | 'paidAmount'>): Decimal | undefined {
  if (isNotNull(expense, ['listedAmount', 'paidAmount'])) {
    return calculateDiscountAmount(expense)
  }
  return undefined
}

/**
 * Calculate the actual amount paid for an expense.  This is the sum of all payments in the payment history,
 * or the `paidAmount` column if the expense is fully paid.
 * 
 * Legacy naming issue: `paidAmount` is actually the post-discount amount.
 */
export function calculateActualPaidAmount(expense: Pick<CompleteExpense, 'paidAmount' | 'is_fully_paid' | 'payment_history'>): Decimal {
  if (expense.is_fully_paid) {
    return new Decimal(expense.paidAmount)
  }
  
  if (expense.payment_history && expense.payment_history.payments.length > 0) {
    return expense.payment_history.payments.reduce((sum, p) => sum.plus(p.amount), new Decimal(0))
  }
  
  return new Decimal(expense.paidAmount)
}

export function maybeCalculateActualPaidAmountFromIncompleteExpense(expense: Pick<ExpenseModel, 'paidAmount' | 'is_fully_paid' | 'payment_history'>): Decimal | undefined {
  if (expense.is_fully_paid && expense.paidAmount) {
    return new Decimal(expense.paidAmount)
  }
  if (expense.payment_history && expense.payment_history.payments.length > 0) {
    return expense.payment_history.payments.reduce((sum, p) => sum.plus(p.amount), new Decimal(0))
  }
  
  if (expense.paidAmount) {
    return new Decimal(expense.paidAmount)
  }
  return undefined
}

export function calculateRemainingOwedAmount(expense: Pick<CompleteExpense, 'paidAmount' | 'is_fully_paid' | 'payment_history'>): Decimal {
  if (expense.is_fully_paid) {
    return new Decimal(0)
  }
  if (expense.payment_history && expense.payment_history.payments.length > 0) {
    return new Decimal(expense.paidAmount).minus(expense.payment_history.payments.reduce((sum, p) => sum.plus(p.amount), new Decimal(0)))
  }
  
  // Legacy expense - assume the user has fully paid the post-discount amount
  return new Decimal(0)
}

export function maybeCalculateRemainingOwedAmountFromIncompleteExpense(expense: Pick<ExpenseModel, 'paidAmount' | 'is_fully_paid' | 'payment_history'>): Decimal | undefined {
  if (expense.is_fully_paid) {
    return new Decimal(0)
  }
  
  if (expense.paidAmount && expense.payment_history && expense.payment_history.payments.length > 0) {
    return new Decimal(expense.paidAmount).minus(expense.payment_history.payments.reduce((sum, p) => sum.plus(p.amount), new Decimal(0)))
  }
  
  if (expense.paidAmount && typeof expense.is_fully_paid != 'boolean') {
    // Legacy expense - assume the user has fully paid the post-discount amount
    return new Decimal(0)
  }
  
  return undefined
}
