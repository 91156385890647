import Decimal from "decimal.js"
import { present } from "./present"

interface CalculateableRow {listedAmount?: string | null, discountAmount?: string | null, paidAmount?: string | null, remainingOwedAmount?: string | null}

type ResultSet = {
  [K in keyof CalculateableRow]?: Decimal
}

export function calculateRemainingAmounts(row: CalculateableRow): ResultSet {
  if (!present(row.listedAmount)) {
    // We can only calculate the listed amount if all the other values are present
    if (present(row.discountAmount) && present(row.paidAmount) && present(row.remainingOwedAmount)) {
      return {listedAmount: new Decimal(row.discountAmount).add(row.paidAmount).add(row.remainingOwedAmount)}
    }
    return {}
  }
  
  // For each combination of the 3 unknown values, where the other 2 are known, calculate the unknown value
  
  const unknowns: Array<keyof CalculateableRow> = []
  const knowns: Array<keyof CalculateableRow> = []
  for (const key of ['discountAmount', 'paidAmount', 'remainingOwedAmount']) {
    if (!present(row[key as keyof CalculateableRow])) {
      unknowns.push(key as keyof CalculateableRow)
    } else {
      knowns.push(key as keyof CalculateableRow)
    }
  }
  
  if (unknowns.length == 3) {
    // not enough known values
    return {}
  }
  
  if (unknowns.length == 1) {
    let remainder = new Decimal(row.listedAmount)
    for (const key of knowns) {
      remainder = remainder.sub(new Decimal(row[key as keyof CalculateableRow]!))
    }
    return {[unknowns[0]]: remainder}
  }
  
  switch (unknowns.sort().join(',')) {
    case 'discountAmount,paidAmount':
      // In this case we know the listed amount and the remaining owed amount
      return {
        discountAmount: new Decimal(row.listedAmount).sub(row.remainingOwedAmount!),
        paidAmount: new Decimal(0)
      }
    case 'discountAmount,remainingOwedAmount':
      // In this case we know the listed amount and the paid amount
      return {
        discountAmount: new Decimal(row.listedAmount).sub(row.paidAmount!),
        remainingOwedAmount: new Decimal(0)
      }
    case 'paidAmount,remainingOwedAmount':
      // In this case we know the listed amount and the discount amount
      return {
        paidAmount: new Decimal(row.listedAmount).sub(row.discountAmount!),
        remainingOwedAmount: new Decimal(0)
      }
    default:
      return {}
  }
  
}
