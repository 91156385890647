import React, { useEffect, useMemo, useRef } from 'react'
import * as PDFJS from 'pdfjs-dist';
import { wait } from 'async-toolbox';
import { assert } from '../../../lib/util/assert';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';

PDFJS.GlobalWorkerOptions.workerSrc = '/pdfjs/build/pdf.worker.mjs'

export function PdfPreview({ file }: { file: File }) {

  // Ensure canvas element is disposed of when the file changes

  const ref = useRef<HTMLCanvasElement>(null)
  const lastRenderedDocument = useRef<File>()
  const uniqueId = useMemo<number>(() => Math.floor(Math.random() * 10_000), [])
  // Add refs to track current render operation
  const currentRenderTask = useRef<PDFJS.RenderTask | null>(null)
  const currentLoadingTask = useRef<PDFJS.PDFDocumentLoadingTask | null>(null)

  const { loading, error } = useAsyncEffect(async () => {
    if (lastRenderedDocument.current === file) { return }
    
    // Cancel any ongoing render task
    if (currentRenderTask.current) {
      await currentRenderTask.current.cancel()
    }
    // Cancel any ongoing loading task
    if (currentLoadingTask.current) {
      await currentLoadingTask.current.destroy()
    }
    
    await wait(400)

    const canvasContext = ref.current?.getContext('2d')
    assert(canvasContext)
    const pdfBytes = await file.arrayBuffer()
    // Store the loading task reference
    currentLoadingTask.current = PDFJS.getDocument(pdfBytes)
    const pdfDoc = await currentLoadingTask.current.promise

    console.log('render page!', file.name)
    const page = await pdfDoc.getPage(1)
    const viewport = page.getViewport({ scale: 1.0 })
    
    // Get container width and calculate scale
    const container = ref.current?.parentElement
    assert(container)
    const containerWidth = container.clientWidth
    const scale = containerWidth / viewport.width

    // Apply the calculated scale
    const scaledViewport = page.getViewport({ scale })
    
    // Set canvas dimensions to match scaled viewport
    const canvas = ref.current
    assert(canvas)
    canvas.width = scaledViewport.width
    canvas.height = scaledViewport.height

    await wait(400)

    // Store the render task reference
    currentRenderTask.current = page.render({
      canvasContext,
      viewport: scaledViewport,
    })
    
    // Wait for render to complete
    await currentRenderTask.current.promise
    lastRenderedDocument.current = file

    // Cleanup function to cancel any pending operations when component unmounts or file changes
    return () => {
      currentRenderTask.current?.cancel()
      currentLoadingTask.current?.destroy()
    }
  }, [file])

  return <div className="preview preview__pdf">
    {loading &&
      <div className="loading-bar w-100" role="status" />}
    {error &&
      <span className="text-danger">Unable to load PDF</span>}
    <canvas id={[file.name, file.lastModified, uniqueId].join('/')} ref={ref} style={{ width: '100%' }} />
  </div>
}
