import { useState } from 'react';
import { ProviderInsertPayload, ProviderModel, addProvider, providerDataSourceLabel } from '../../reduxToolkit/providersSlice';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch } from '../../hooks/reduxToolkit';
import { present } from '../../../lib/util/present';

interface NewProviderRowFormProps {
  membershipId: string
  prefilledData?: Partial<ProviderModel>
  disabledFields?: Array<keyof ProviderInsertPayload>
  
  onInserted: (provider: ProviderInsertPayload) => void;
}

export function NewProviderRowForm({ membershipId, onInserted, prefilledData, disabledFields }: NewProviderRowFormProps) {
  const dispatch = useAppDispatch();
  const [name, setName] = useState(prefilledData?.name ?? '');
  const [phone, setPhone] = useState(prefilledData?.billing_phone ?? '');
  const [address, setAddress] = useState(prefilledData?.street_address ?? '');
  const [email, setEmail] = useState(prefilledData?.billing_email ?? '');
  const [website, setWebsite] = useState(prefilledData?.website ?? '');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const now = new Date().toISOString()
    const id = uuidv4()
    
    const didChangePhone = present(phone) && phone !== prefilledData?.billing_phone
    const didChangeEmail = present(email) && email !== prefilledData?.billing_email
    const didChangeAddress = present(address) && address !== prefilledData?.street_address
    const didChangeWebsite = present(website) && website !== prefilledData?.website
    
    const provider: ProviderInsertPayload = {
      id,
      membership_id: membershipId,
      name,
      billing_phone: phone,
      billing_phone_source: didChangePhone ? 'manual' : prefilledData?.billing_phone_source,
      billing_email: email,
      billing_email_source: didChangeEmail ? 'manual' : prefilledData?.billing_email_source,
      street_address: address,
      street_address_source: didChangeAddress ? 'manual' : prefilledData?.street_address_source,
      website: website,
      website_source: didChangeWebsite ? 'manual' : prefilledData?.website_source,
      // If they change the address, clear out the Google Places link.
      google_places_id: didChangeAddress ? null : prefilledData?.google_places_id,
      created_at: now,
      updated_at: now
    };

    dispatch(addProvider(provider));
    onInserted(provider);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="provider-name" className="form-label">Provider Name</label>
        <input
          type="text"
          className="form-control"
          id="provider-name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          disabled={disabledFields?.includes('name')}
          placeholder="Enter provider name"
        />
      </div>

      <div className="mb-3">
        <label htmlFor="provider-phone" className="form-label">Phone Number</label>
        <input
          type="tel"
          className="form-control"
          id="provider-phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          disabled={disabledFields?.includes('billing_phone')}
          placeholder="Enter phone number"
        />
        {prefilledData?.billing_phone_source && (prefilledData.billing_phone === phone) &&
          <div className="form-text">
            <span className="text-muted text-sm">
              {providerDataSourceLabel(prefilledData.billing_phone_source)}
            </span>
          </div>}
      </div>

      <div className="mb-3">
        <label htmlFor="provider-email" className="form-label">Billing Email</label>
        <input
          type="email"
          className="form-control"
          id="provider-email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={disabledFields?.includes('billing_email')}
          placeholder="Enter billing email"
        />
        {prefilledData?.billing_email_source && (prefilledData.billing_email === email) &&
          <div className="form-text">
            <span className="text-muted text-sm">
              {providerDataSourceLabel(prefilledData.billing_email_source)}
            </span>
          </div>}
      </div>

      <div className="mb-3">
        <label htmlFor="provider-website" className="form-label">Website</label>
        <input
          type="url"
          className="form-control"
          id="provider-website"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
          disabled={disabledFields?.includes('website')}
          placeholder="Enter website"
        />
        {prefilledData?.website_source && (prefilledData.website === website) &&
          <div className="form-text">
            <span className="text-muted text-sm">
              {providerDataSourceLabel(prefilledData.website_source)}
            </span>
          </div>}
      </div>

      <div className="mb-3">
        <label htmlFor="provider-address" className="form-label">Address</label>
        <textarea
          className="form-control"
          id="provider-address"
          rows={3}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          disabled={disabledFields?.includes('street_address')}
          placeholder="Enter provider address"
        />
        {prefilledData?.street_address_source && (prefilledData.street_address === address) &&
          <div className="form-text">
            <span className="text-muted text-sm">
              {providerDataSourceLabel(prefilledData.street_address_source)}
            </span>
          </div>}
      </div>

      <button type="submit" className="btn btn-primary">
        Save Provider
      </button>
    </form>
  );
} 
