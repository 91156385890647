import { formatDateInTimeZone } from "../../../../../../lib/formatDateInTimeZone";
import { assert } from "../../../../../../lib/util/assert";
import { isMobile } from "../../../../../../lib/util/isMobile";
import { slugify } from "../../../../../../lib/util/slugify";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxToolkit";
import { useLoadBlobs } from "../../../../../hooks/useLoadBlobs";
import { useUser } from "../../../../../hooks/useUser";
import { useFeature } from "../../../../../providers/featureFlags";
import { completeSubmission } from "../../../../../reduxToolkit/actions/completeSubmission";
import { SubmissionById } from "../../../../../reduxToolkit/selectors/submissionById";
import { isSubmissionData2024 } from "../../../../../reduxToolkit/submissionsSlice";
import { EmailMethodTab } from "../emailMethodTab";
import { useResetSubmission } from "../../../../../hooks/useResetSubmission";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { CopyableSpan } from "../../../../../components/copyableSpan";
import { ExpenseTable } from "../../../../../components/expenseTable";
import { useAsyncAction } from "../../../../../hooks/useAsyncAction";
import { useClient } from "../../../../../providers/supabase";
import { Attachment } from "../../../../../components/attachment";

interface SubmissionComplete2024Props {
  submission: SubmissionById
  
  onSubmitted: () => void
}

export function SubmissionNextSteps2024({submission, onSubmitted}: SubmissionComplete2024Props) {
  const data = submission.data
  if (!isSubmissionData2024(data)) { throw new Error('Invalid submission data: ' + (data as any)?._version) }
  if (data.type !== 'CHM' && data.type !== 'addon') { throw new Error('Invalid submission type: ' + data.type) }
  
  const isMaternity = submission.incident.is_maternity ||
    'maternityVerificationFormData' in data && data.maternityVerificationFormData.isMaternityIncident

  const dispatch = useAppDispatch()
  const user = useUser()

  const pendingExpenseIDs = data.medicalBillWorksheetData.expenseRows.map((r) => r.expenseId)
  const pendingExpenses = useAppSelector((s) => s.expenses.expenses.filter((e) => pendingExpenseIDs.includes(e.id)))
  const allAttachmentRecords = [submission.id, submission.incident_id, ...(submission.expense_ids || []), ...pendingExpenseIDs]
  const attachments = useAppSelector((s) => s.attachments.attachments.filter((a) => allAttachmentRecords.includes(a.record_id)))
  
  const submissionPacket = attachments.filter((a) => a.record_id == submission.id)

  const finishSubmission = () => {
    dispatch(completeSubmission({
      submission: submission,
      attachments,
      pendingExpenses: pendingExpenses,
      submittedByUserId: user?.id || '',
    }))
      .finally(() => {
        onSubmitted()
      })
  }

  const [resetSubmissionState, resetSubmission] = useResetSubmission(submission)
  
  const zipFileName = `${formatDateInTimeZone(submission.incident.start_date, { format: 'yyyy-MM-dd' })}_${slugify(submission.incident.description || '')}`
  
  const emailTabShown = useFeature('share_chm_submission')
  const mobile = isMobile()
  const canShare = typeof navigator.share === 'function'
  const emailTabShownFirst = emailTabShown && mobile && canShare

  return <div className="submitToChm2024-SubmissionComplete row">
    <div className="col-12">
      {data.type === 'CHM' ?
        <h2>Upload to CHM</h2> :
        <h2>Upload Addon to CHM</h2>}
        
      <p>
        We've filled out your documents! You can download them below.
      </p>
    </div>
    <div className="col-12 col-lg-6 submit-to-chm-2024__file-browser">
      <h4>Submission Packet</h4>
      {submissionPacket.map((a) => 
        <Attachment key={a.id} attachment={a} />
      )}
      <h4 className="mt-4">Your Medical Bills</h4>
      <ExpenseTable
        expenses={pendingExpenses}
        displayOptions={{ hideIncident: true, expandAll: !isMobile() }}
        disableEdit={true}
      />
    </div>
    <div className="col-12 col-lg-6 submit-to-chm-2024__next-steps">
      <ul className="nav nav-tabs" id="myTab" role="tablist" style={!emailTabShown ? { display: 'none' } : {}}>
        <li className="nav-item" role="presentation">
          <button id="chm-portal-tab"
            className={`nav-link ${!emailTabShownFirst && 'active'}`}
            data-bs-toggle="tab" data-bs-target="#chm-portal-tab-pane" type="button" role="tab"
            aria-controls="home-tab-pane" aria-selected="true">
              Browser Method
            </button>
        </li>
        {emailTabShown && <li className="nav-item" role="presentation">
                
          <button id="email-tab"
            className={`nav-link ${emailTabShownFirst && 'active'}`}
            data-bs-toggle={'tab'} data-bs-target="#email-tab-pane" type="button" role="tab"
            aria-controls="profile-tab-pane" aria-selected="false">
              Email Method
          </button>
        </li>}
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className={`tab-pane ${!emailTabShownFirst && 'show active'}`} id="chm-portal-tab-pane" role="tabpanel" aria-labelledby="chm-portal-tab" tabIndex={0}>
          {isMobile() ?
            <EmailMyselfALink submission={submission} emailTabShown={emailTabShown} /> :
            <>
              {submission.submission_type == 'CHM' ?
                <BrowserMethod submission={submission} isMaternity={!!isMaternity} finishSubmission={finishSubmission} /> :
                <BrowserMethodAddon submission={submission} finishSubmission={finishSubmission} />}
            </>}
        </div>
        {emailTabShown && <div className={`tab-pane ${emailTabShownFirst && 'show active'}`} id="email-tab-pane" role="tabpanel" aria-labelledby="email-tab" tabIndex={1}>
          <EmailMethodTab incident={submission.incident} attachments={attachments} zipFileName={zipFileName} />
        </div>}
      </div>
    </div>

    <div className="col-12 mt-2">
      <div className="row">
        <div className="col-12 col-lg-6 offset-lg-6">
          <button onClick={finishSubmission}
              className={`btn w-100 btn-primary`}>
            I've submitted my incident to CHM
          </button>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 col-lg-6 offset-lg-6">
          <button onClick={resetSubmission}
              disabled={resetSubmissionState.loading}
              className={`btn w-100 btn-outline-danger ${resetSubmissionState.loading && 'disabled'}`}>
            Delete Forms and Start Over
          </button>
        </div>
      </div>
    </div>
  </div>
  
}

const standardFormSteps = [
  'lets-get-started',
  'tell-us-about-the-incident',
  'other-forms-of-payment',
  'upload-medical-bills',
]

const maternityFormSteps = [
  'lets-get-started',
  'tell-us-about-the-incident',
  'maternity',
  'other-forms-of-payment',
  'upload-medical-bills',
]

function EmailMyselfALink({ submission, emailTabShown }: { submission: SubmissionById, emailTabShown: boolean }) {
  const nav = useLocation()
  const client = useClient()
  
  const [emailMeState, emailMe] = useAsyncAction(async () => {
    const origin = typeof window !== 'undefined' ? window.location.origin : 'https://app.healthsharetech.com'
    const url = new URL(nav.pathname, origin)
    url.hash = nav.hash
    url.search = nav.search
    
    const { error } = await client.functions.invoke('email-myself', {
      body: JSON.stringify({
        subject: `Your Requested Submission Link`,
        html: `
          <p>
          You requested we send you a direct link to your in-progress submission, so that you can continue on your computer.
          Here is your link for this incident:
          </p>
          <p>
          ${submission.incident.description} on ${formatDateInTimeZone(submission.incident.start_date, { format: 'yyyy-MM-dd' })}
          </p>
          <a href="${url.href}">${url.href}</a>
        `
      })
    })
    if (error) { throw new Error(error.message) }
    
    return true
  }, [nav, client, submission])
  
  return <>
      <p>
        The CHM portal doesn't work well on mobile devices.
        We can email you a link to this submission so you can continue
        on your computer.
        <br />
        {emailMeState.data ?
          <>
            <br />
            <span className="text-success">Email sent!</span> Check your inbox for a message from us.<br/>
            Didn't get it? <button onClick={emailMe} className={`btn btn-link ${emailMeState.loading && 'disabled'}`}>
              Send the email again
            </button>
          </> :
          <button onClick={emailMe} className={`btn btn-link ${emailMeState.loading && 'disabled'}`}>
            Email Me a Link to this Page
          </button>}
        {emailMeState.error && <p className="text-danger">{emailMeState.error.message}</p>}
      </p>
      {emailTabShown && <p>
        The other option is to use the Email Method by clicking on the other tab above.
      </p>}
    </>
}

function BrowserMethod({ submission, isMaternity, finishSubmission }: { submission: SubmissionById, isMaternity: boolean, finishSubmission: () => void }) {
  const [stepIndex, setStepIndex] = useState<number>(0)
  const formSteps = isMaternity ? maternityFormSteps : standardFormSteps
  const step = formSteps[stepIndex]
  
  const data = submission.data
  if (!isSubmissionData2024(data)) { throw new Error('Invalid submission data: ' + (data as any)?._version) }
  if (data.type != 'CHM') { throw new Error('Invalid submission type: ' + data.type) }
  
  let checklist: React.ReactNode
  switch(step) {
    case 'lets-get-started':
      checklist =
      <ul className="checklist">
        <li className="checklist__item">
          <p>
            Log In to the new Membership portal, find the "Sharing" drop-down, and click "Submit Medical Bills" <br/>
            <a href="https://member-portal.chministries.org/new-need" target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
              https://member-portal.chministries.org/new-need</a>
          </p>
        </li>
        
        <li className="checklist__item">
          <p>
            Choose "{submission.incident.patient_name} (born {formatDateInTimeZone(submission.incident.patient_dob, { format: 'MMM d, yyyy' })})"
            from the patient dropdown.
          </p>
        </li>
        
        <li className="checklist__item">
          <p>
            Check the attestation and consent checkboxes, and sign your name.
          </p>
        </li>
        
        <li className="checklist__item">
          <p>
            Click "Next" in the CHM portal.
          </p>
        </li>
      </ul>
      break
    case 'tell-us-about-the-incident':
      if (isMaternity) {
        checklist =
          <ul className="checklist">
            <li className="checklist__item">
              <p>
                select "Maternity" and click "Next" in the CHM portal.
              </p>
            </li>
          </ul>
      } else {
        checklist =
          <ul className="checklist">
            <li className="checklist__item">
              <p>
                select "Standard Incident"
              </p>
            </li>
            
            <li className="checklist__item">
              <p>
                Paste this into the "Incident Details" field:
                <br/>
                <br/>
                <CopyableSpan>
                  {submission.incident.description}
                </CopyableSpan>
              </p>
            </li>
            
            <li className="checklist__item">
              <p>
                Set the date to {formatDateInTimeZone(submission.incident.start_date, { format: 'MM/dd/yyyy' })}
              </p>
            </li>
            
            <li className="checklist__item">
              <p>
                Paste this into the "Tell us what happened" field:
                <br/>
                <br/>
                <CopyableSpan>
                  {data.sharingRequestFormData.letterOfExplanation || ''}
                </CopyableSpan>
              </p>
            </li>
          </ul>
      }
      break
      
    case 'maternity':
      checklist =
        <ul className="checklist">
          <li className="checklist__item">
            <p>
              Check the "I Understand" checkbox.
            </p>
          </li>
          <li className="checklist__item">
            <p>
              Enter the baby's due date
              <br/>
              <br/>
              <CopyableSpan>
                {data.maternityVerificationFormData.dueDate && formatDateInTimeZone(data.maternityVerificationFormData.dueDate, { format: 'MM/dd/yyyy' }) || ''}
              </CopyableSpan>
            </p>
          </li>
          <li className="checklist__item">
            <p>
              Skip the "Adding your baby" section.  You should inform your Plan Administrator to add your baby to your plan.
            </p>
          </li>
          <li className="checklist__item">
            <p>
              Click "Next" in the CHM portal.
            </p>
          </li>
        </ul>
      break
      
    case 'other-forms-of-payment':
      checklist =
        <ul className="checklist">
          <li className="checklist__item">
            <p>
              On the "Other Forms of Payment" page, choose "No" for both checkboxes.
            </p>
          </li>
        </ul>
      break
      
    case 'upload-medical-bills':
      checklist = <p>
        Under "Upload medical bills or estimates", upload each of your itemized bills.
        Then check the acknowledgement checkbox and click "Submit".
      </p>
      break
  }
  
  return <>
    <h4>Next Steps:</h4>
    {checklist}
    <div className="d-flex justify-content-between">
      {stepIndex > 0 ?
        <button onClick={() => setStepIndex(stepIndex - 1)} className="btn btn-outline-primary d-flex align-items-center">
          <i className="material-icons">arrow_back</i> Back
        </button> : <span>&nbsp;</span>}
      {stepIndex < formSteps.length - 1 &&
        <button onClick={() => setStepIndex(stepIndex + 1)} className="btn btn-primary d-flex align-items-center">
          Next <i className="material-icons">arrow_forward</i>
        </button>}
      {stepIndex === formSteps.length - 1 &&
        <button onClick={finishSubmission} className="btn btn-primary d-flex align-items-center">
          Finish
        </button>}
    </div>
  </>
}

function BrowserMethodAddon({ submission, finishSubmission }: { submission: SubmissionById, finishSubmission: () => void }) { 
  
  let checklist: React.ReactNode
  checklist =
      <ul className="checklist addon-next-steps">
        <li className="checklist__item">
          <p>
            Log In to the new Membership portal, find your incident in the right hand side, and click "Add Bills" <br/>
            <a href="https://member-portal.chministries.org" target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
              https://member-portal.chministries.org</a>
          </p>
        </li>
        
        <li className="checklist__item">
          <p>
            Check "Additional Bills" and click "Next"
          </p>
        </li>
        
        <li className="checklist__item">
          <p>
            Under "Upload medical bills or estimates", upload the files and fill the data for each bill,
            then click "Next"
          </p>
        </li>
        
        <li className="checklist__item">
          <p>
            Review the data you entered and click "Submit".
          </p>
        </li>
      </ul>
      
  return <>
    <h4>Next Steps:</h4>
    {checklist}
  </>
}
