import { VirtualToDo } from "../../../lib/todos/types";
import { present } from "../../../lib/util/present";
import { RootState } from "../store";
import { selectToDosForExpense, selectToDosForIncident, selectToDosForSubmission } from "./todos";

/**
 * Given an incident ID, returns a list of all todos that reference that incident either directly or indirectly
 * through an expense or submission.
 * 
 * This is useful for determining if an incident has any todos that are not yet complete.
 */
export function selectAllTodosReferencingIncident(incidentId: string): (s: RootState) => VirtualToDo[] {
  return (s: RootState) => {
    const expenseIds = s.expenses.expenses.filter((e) => e.incident_id === incidentId).map((e) => e.id)
    const submissionIds = s.submissions.submissions.filter((s) => s.incident_id === incidentId).map((s) => s.id)
    
    return [
      ...selectToDosForIncident(incidentId)(s),
      ...expenseIds.flatMap((expenseId) => selectToDosForExpense(expenseId)(s)),
      ...submissionIds.flatMap((submissionId) => selectToDosForSubmission(submissionId)(s)),
    ].filter(present)
  }
}
