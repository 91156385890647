import { AuthApiError, Session } from "@supabase/supabase-js"
import * as Sentry from "@sentry/react";
import React, { useState } from "react"
import { useAsyncAction } from "../../hooks/useAsyncAction"
import { useClient } from "../../providers/supabase"
import { useNavigate } from "react-router"
import { present } from "../../../lib/util/present"

interface SignInFormProps {
  onLoginSuccess?: (data: {session: Session}) => any
}

interface SignInFormState {
  email: string
}

export function SignInWithEmailForm({
  onLoginSuccess
}: SignInFormProps) {
  const client = useClient()
  const navigate = useNavigate()
  const [state, setState] = useState<SignInFormState>({
    email: '',
  })

  const [{loading: submitting, error}, submit] = useAsyncAction(async (e: React.FormEvent) => {
    e.preventDefault()
    if (!state.email) { throw new Error(`Email is required`) }
    
    // Set user prior to login in case the login fails
    Sentry.setUser({
      email: state.email,
    })

    const resp = await client.auth.signInWithOtp({
      email: state.email,
    })
    if (resp.error) {
      throw resp.error
    }

    navigate(`/verifyOtp?email=${state.email}`)
    return resp.data
  }, [onLoginSuccess, state.email])

  const canSubmit = present(state.email)

  return <form onSubmit={submit}>
      <div className="mb-3">
        <span className='form-label'>Email</span>
        <input name="email" type="text"
          className="form-control"
          required
          disabled={submitting}
          value={state.email}
          onChange={(e) => { setState({...state, email: e.target.value }) }}></input>
      </div>

      <div className="mb-3 d-flex">
      {submitting ?
        <button className='btn btn-primary disabled'>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </button> :
        <button type='submit' className='btn btn-primary' disabled={!canSubmit}>Submit</button>}
      </div>

      <div className="mb-3">
        {error &&
          <p className="text-danger">
            {error.message}
          </p>}
      </div>

  </form>
}
