import { useState } from "react"
import { useClient } from "../providers/supabase"
import { useAsyncEffect } from "./useAsyncEffect"
import { useUser } from "./useUser"
import { PromoCodeRedemptionRow } from "../../types/supabase"
import { present } from "../../lib/util/present"


export function useQueryPromoCodeRedemptionsForUser() {
  const client = useClient()
  const user = useUser()

  const [redemptions, setRedemptions] = useState<PromoCodeRedemptionRow[]>([])
  const {loading, error, refreshing} = useAsyncEffect(async () => {
    if (!present(user?.id)) {
      throw new Error('User is not signed in')
    }

    const redemptions = await client.from('promo_code_redemptions')
      .select('*')
      .eq('user_id', user.id)

    setRedemptions(redemptions.data || [])
  }, [user?.id, client])

  return [redemptions, { loading: loading || refreshing, error }] as const
}
