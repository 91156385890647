import { useNavigate, useParams } from 'react-router-dom';
import { addProvider, deleteProvider, ProviderInsertPayload, ProviderModel } from '../../reduxToolkit/providersSlice';
import { NewProviderRowForm } from '../../components/forms/newProviderRowForm';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxToolkit';
import max from "lodash/max";
import { useEffect, useState } from 'react';
import { ExpenseModel, ExpenseUpdatePayload, updateExpense } from '../../reduxToolkit/expensesSlice';
import { useFuseSearch } from '../../hooks/useFuseSearch';
import { useConfirm } from '../../providers/confirmDialog';
import { v4 as uuidv4 } from 'uuid';
import { assert } from '../../../lib/util/assert';
import { useGooglePlacesSearch } from '../../hooks/useGooglePlacesSearch';
import { Tooltip } from '../../components/tooltip';
import { ProviderDataSource } from '../../../types/supabase';
import { GooglePlaceResult } from '../../../lib/google/places';

import './newProvider.scss'

export function NewProvider() {
  
  const params = useParams()
  const referenceExpenseId = params.expenseId
  const referenceExpense = useAppSelector((s) => s.expenses.expenses.find((e) => e.id === referenceExpenseId))
  
  const [view, setView] = useState<'new-provider' | 'link-provider'>(
    referenceExpenseId ? 'link-provider' : 'new-provider'
  )
  
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  useEffect(() => {
    if (!referenceExpenseId) {
      setLoading(false)
      return
    }
    
    if (referenceExpense) {
      setLoading(false)
      return
    }

    const timeout =setTimeout(() => {
      setLoading(false)
      setError('Failed to load expense')
    }, 5000)
    
    return () => clearTimeout(timeout)
  }, [referenceExpenseId, referenceExpense])
  
  if (error) {
    throw new Error(error)
  }
  
  if (loading) {
    return <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  }

  if (view == 'link-provider') {
    assert(referenceExpense, 'referenceExpense should be loaded')
    return <LinkProvider referenceExpense={referenceExpense} setView={setView} />
  }

  return <CreateProvider referenceExpense={referenceExpense} />
}

function LinkProvider({ referenceExpense, setView }: { referenceExpense: ExpenseModel, setView: (view: 'new-provider' | 'link-provider') => void }) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const membershipId = useAppSelector((s) => s.membership.membershipId)
  const allProviders = useAppSelector((s) => s.providers?.providers || [])
  const updatedAt = max(allProviders?.map((e) => e.updated_at)) || 0
  const [selectedProviderId, setSelectedProvider] = useState<string>()
  
  const [fuzzySearchResults, fuzzySearch] = useFuseSearch(
    allProviders,
    {
      findAllMatches: true,
      includeScore: true,
      keys: ['name', 'street_address']
    },
    [updatedAt]
  )
  
  useEffect(() => {
    if (!referenceExpense.provider) { return }
    
    fuzzySearch(referenceExpense.provider, {
      limit: 3
    })
  }, [referenceExpense?.provider])
  
  let placesQuery = referenceExpense.provider
  if (referenceExpense.provider_extra_data?.street_address) {
    if (placesQuery) {
      placesQuery = `${placesQuery}, ${referenceExpense.provider_extra_data.street_address}`
    } else {
      placesQuery = referenceExpense.provider_extra_data.street_address
    }
  }

  const [selectedGooglePlaceId, setSelectedGooglePlaceId] = useState<string>()
  const [places, placesState] = useGooglePlacesSearch(placesQuery, { limit: 3 }, [placesQuery])
  
  const loading = fuzzySearchResults == undefined || placesState.loading
  
  useEffect(() => {
    if (loading) { return }

    if (fuzzySearchResults && fuzzySearchResults.length == 0 && (places && places.length == 0)) {
      setView('new-provider')
    }
  }, [loading])
  
  const handleLink = (provider: { id: string, name?: string }) => {
    if (!provider.id || !referenceExpense?.id) { return }
    
    const now = new Date().toISOString()
    const expenseUpdate: ExpenseUpdatePayload = {
      id: referenceExpense.id,
      provider_id: provider.id,
      updated_at: now
    }
    if (provider.name) {
      expenseUpdate.provider = provider.name
    }
    dispatch(updateExpense(expenseUpdate))
    
    navigate(`/expenses/${referenceExpense.id}`)
  }
  
  const handleLinkGooglePlace = (placeId: string) => {
    if (!placeId || !referenceExpense?.id) { return }
    
    const place = places?.find((p) => p.id == placeId)
    assert(place, 'place should be found')
    
    // Create a new provider based on the place
    const providerId = uuidv4()
    const now = new Date().toISOString()
    
    let website = place.websiteUri
    if (website) {
      try {
        const url = new URL(website)
        // Remove tracking parameters
        url.searchParams.delete('utm_source')
        url.searchParams.delete('utm_medium') 
        url.searchParams.delete('utm_campaign')
        url.searchParams.delete('utm_term')
        url.searchParams.delete('utm_content')
        website = url.toString()
      } catch (e) {
        // Invalid URL, leave as-is
      }
    }
    let websiteSource: ProviderDataSource | null = website ? 'google_places' : null
    if (!website && referenceExpense.provider_extra_data?.website) {
      website = referenceExpense.provider_extra_data.website
      websiteSource = 'textract'
    }
    
    let billingPhone = place.nationalPhoneNumber
    let billingPhoneSource: ProviderDataSource | null = place.nationalPhoneNumber ? 'google_places' : null
    if (!billingPhone && referenceExpense.provider_extra_data?.billing_phone) {
      billingPhone = referenceExpense.provider_extra_data.billing_phone
      billingPhoneSource = 'textract'
    }
    
    dispatch(addProvider({
      id: providerId,
      name: place.displayName.text,
      street_address: place.formattedAddress,
      street_address_source: 'google_places',
      created_at: now,
      updated_at: now,
      membership_id: membershipId,
      billing_phone: billingPhone,
      billing_phone_source: billingPhoneSource,
      website: website,
      website_source: websiteSource,
      google_places_id: place.id
    }))
    
    handleLink({
      id: providerId,
      name: place.displayName.text
    })
  }
  
  const existingProvidersToShow: ProviderModel[] = []
  const googlePlacesToShow: GooglePlaceResult[] = []
  if (fuzzySearchResults) {
    existingProvidersToShow.push(...fuzzySearchResults)
  }
  if (places) {
    for (const place of places) {
      // If a google place result matches an existing provider, prefer that provider
      const matchingExistingProvider = existingProvidersToShow.find((p) => p.google_places_id == place.id)
      if (matchingExistingProvider) {
        if (!existingProvidersToShow.find((p) => p.id == matchingExistingProvider.id)) {
          existingProvidersToShow.push(matchingExistingProvider)
        }
      } else {
        googlePlacesToShow.push(place)
      }
    }
  }
  
  return <div className="container">
    <div className="row">
      <div className="col-12">
        <h1 className="new-provider__title">
          Link Provider to Expense
        </h1>
        <div className='new-provider__providers-list'>
          {existingProvidersToShow.map((provider) => {
            return <div className={`new-provider__providers-list-row new-provider__providers-list-row--existing row ${selectedProviderId == provider.id ? 'selected' : ''}`}
                  onClick={() => {
                    if (selectedProviderId == provider.id) {
                      handleLink({
                        id: selectedProviderId,
                        name: provider.name
                      })
                    } else {
                      setSelectedGooglePlaceId(undefined)
                      setSelectedProvider(provider.id)
                    }
                  }}
                  onDoubleClick={() => {
                    handleLink({
                      id: provider.id,
                      name: provider.name
                    })
                  }}>
                <div className='col-10'>
                  {provider.name}
                </div>
                <div className='col-2'>
                  <ProviderActions provider={provider} />
                </div>
                
                <div className='col-12 col-md-6 mt-2 new-provider__providers-list-row__contact-info'>
                  <div className='row'>
                    <div className='col-2'>
                      <i className='material-icons'>location_on</i>
                    </div>
                    <div className='col-10 new-provider__providers-list-row__address'>
                      <pre>{provider.street_address || '(not found)'}</pre>
                    </div>
                    
                    <div className='col-2'>
                      <i className='material-icons'>phone</i>
                    </div>
                    <div className='col-10'>
                      {provider.billing_phone || '(not found)'}
                    </div>
                    
                    <div className='col-2'>
                      <i className='material-icons'>link</i>
                    </div>
                    <div className='col-10'>
                      {provider.website || '(not found)'}
                    </div>
                    
                    <div className='col-2'>
                      <i className='material-icons'>mail</i>
                    </div>
                    <div className='col-10'>
                      {provider.billing_email || '(not found)'}
                    </div>
                  </div>
                </div>
              </div>
          })}
          
          {googlePlacesToShow.slice(0, 4).map((place) => {
            const [street, ...rest] = place.formattedAddress.split(',')
            const formattedAddress = `${street}\n${rest.join(',').trim()}`
            
            return <div className={`new-provider__providers-list-row new-provider__providers-list-row--google row ${selectedGooglePlaceId == place.id ? 'selected' : ''}`}
                  onClick={() => {
                    if (selectedGooglePlaceId == place.id) {
                      handleLinkGooglePlace(place.id)
                    } else {
                      setSelectedProvider(undefined)
                      setSelectedGooglePlaceId(place.id)
                    }
                  }}
                  onDoubleClick={() => {
                    handleLinkGooglePlace(place.id)
                  }}>
              <div className='col-10'>
                  {place.displayName.text}<br/>
                  <small className='text-muted'>Found on Google Maps</small>
                </div>
                <div className='col-2'>
                  <Tooltip tooltip='This provider was found on Google Maps'>
                    <i className='material-icons'>pin_drop</i>
                  </Tooltip>
                </div>
                
                <div className='col-12 col-md-6 mt-2 new-provider__providers-list-row__contact-info'>
                  <div className='row'>
                    
                    <div className='col-2'>
                      <i className='material-icons'>location_on</i>
                    </div>
                    <div className='col-10 new-provider__providers-list-row__address'>
                      <pre>{formattedAddress}</pre>
                    </div>
                    
                    <div className='col-2'>
                      <i className='material-icons'>phone</i>
                    </div>
                    <div className='col-10'>
                      {place.nationalPhoneNumber || '(not found)'}
                    </div>
                    
                    <div className='col-2'>
                      <i className='material-icons'>link</i>
                    </div>
                    <div className='col-10'>
                      {place.websiteUri || '(not found)'}
                    </div>
                  </div>
                </div>
            </div>
          })}
          
          {(selectedProviderId || selectedGooglePlaceId) && <div className='row new-provider__providers-list-actions'
              onClick={() => {
                if (selectedProviderId) {
                  handleLink({
                    id: selectedProviderId,
                    name: allProviders.find((p) => p.id == selectedProviderId)?.name
                  })
                } else if (selectedGooglePlaceId) {
                  handleLinkGooglePlace(selectedGooglePlaceId)
                }
              }}>
            <div className='col-10'>
              Link to Expense
            </div>
            <div className='col-2'>
              <button className='btn btn-link'>
                <i className='material-icons'>link</i>
              </button>
            </div>
          </div>}
          
          {(!selectedProviderId && !selectedGooglePlaceId) && <div className='new-provider__providers-list-row row'
              onClick={() => setView('new-provider')}>
            <div className='col-10'>
              My Provider is not listed here
            </div>
            <div className='col-2'>
              <button className='btn btn-link'>
                <i className='material-icons'>add</i>
              </button>
            </div>
          </div>}
        </div>
      </div>
    </div>
  </div>
}

function ProviderActions({ provider }: { provider: ProviderModel }) {
  const [confirm] = useConfirm()
  const dispatch = useAppDispatch()
  
  const confirmDelete = () => {
    confirm({
      title: 'Delete Provider',
      message: 'Are you sure you want to delete this provider?',
      onConfirm: () => {
        const now = new Date().toISOString()
        dispatch(deleteProvider({
          id: provider.id,
          updated_at: now,
          deleted_at: now
        }))
      }
    })
  }
  
  return <div className="dropdown">
    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i className='material-icons'>more_vert</i>
    </button>
    <ul className="dropdown-menu">
      <li>
        <button className="dropdown-item" onClick={confirmDelete}>
          <i className='material-icons me-2'>delete</i>
          Delete
        </button>
      </li>
    </ul>
  </div>
}

function CreateProvider({ referenceExpense }: { referenceExpense?: ExpenseModel }) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const membershipId = useAppSelector((s) => s.membership.membershipId)
  
  const onInserted = (provider: ProviderInsertPayload) => {
    if (referenceExpense?.id) {
      // Link the provider to the expense
      const now = new Date().toISOString()
      dispatch(updateExpense({
        id: referenceExpense.id,
        provider: provider.name,
        provider_id: provider.id,
        updated_at: now
      }))
      
      navigate(`/expenses/${referenceExpense.id}`)
    } else {
      navigate(`/providers/${provider.id}`)
    }
  }
  
  const prefilledData: Partial<ProviderModel> | undefined = referenceExpense ? {
    name: referenceExpense.provider || undefined,
    billing_phone: referenceExpense.provider_extra_data?.billing_phone,
    billing_phone_source: 'textract',
    billing_email: referenceExpense.provider_extra_data?.billing_email,
    billing_email_source: 'textract',
    street_address: referenceExpense.provider_extra_data?.street_address,
    street_address_source: 'textract',
    website: referenceExpense.provider_extra_data?.website,
    website_source: 'textract'
  } : undefined
  
  return <div className="container">
    <div className="row">
      <div className="col-12">
        <h1 className="new-provider__title">
          Create New Provider
        </h1>
        <p>
          Fill in as much information as you can from your receipt.  You don't have to fill in all the fields.
        </p>
        <NewProviderRowForm membershipId={membershipId} onInserted={onInserted} prefilledData={prefilledData} />
      </div>
    </div>
  </div>
}
