import { present } from "async-toolbox"
import { SubmissionData2024CHM, SubmissionData2024CHMAddon, SubmissionData2024Expense } from "../../../../reduxToolkit/submissionsSlice"
import { Decimal } from "decimal.js"

export type ValidationError = {
  field: string
  message: string
}

export function validateSubmissionData2024CHM(
  data: SubmissionData2024CHM
): {
  [key in keyof SubmissionData2024CHM]?: ValidationError[]
} {
  return {
    sharingRequestFormData: validateSharingRequestFormData2024(data.sharingRequestFormData),
    medicalBillWorksheetData: validateMedicalBillWorksheetData2024(data.medicalBillWorksheetData),
    medicalInformationReleaseData: validateMedicalInformationReleaseData2024(data.medicalInformationReleaseData),
    maternityVerificationFormData: validateMaternityVerificationFormData2024(data.maternityVerificationFormData),
  }
}

export function validateSubmissionData2024CHMAddon(
  data: SubmissionData2024CHMAddon
): {
  [key in keyof SubmissionData2024CHMAddon]?: ValidationError[]
} {
  return {
    medicalBillWorksheetData: validateMedicalBillWorksheetData2024(data.medicalBillWorksheetData),
  }
}

export function validateSharingRequestFormData2024(data: SubmissionData2024CHM['sharingRequestFormData']): ValidationError[] {
  const errors: ValidationError[] = []
  if (!data) { return errors }
  
  const presenceFields: (keyof SubmissionData2024CHM['sharingRequestFormData'])[] = [
    'memberNumber',
    'memberName',
    'phone',
    'email',
    'patientName',
    'patientDOB',
    'illness',
    'illnessDate',
    'letterOfExplanation',
    'signatureData',
    'signatureDate',
  ]
  presenceFields.forEach((field) => {
    if (!present(data[field])) {
      errors.push({
        field,
        message: 'This field is required'
      })
    }
  })
  
  return errors
}

export function validateMedicalBillWorksheetData2024(data: SubmissionData2024CHM['medicalBillWorksheetData']): ValidationError[] {
  const errors: ValidationError[] = []
  if (!data) { return errors }
  
  if (typeof data.isAddOn === 'undefined' || data.isAddOn === null) {
    errors.push({
      field: 'isAddOn',
      message: 'Please choose an option'
    })
  }
  
  const rootPresenceFields: (keyof SubmissionData2024CHM['medicalBillWorksheetData'])[] = [
    'patientName',
  ]
  rootPresenceFields.forEach((field) => {
    if (!present(data[field])) {
      errors.push({
        field,
        message: 'This field is required'
      })
    }
  })
  
  data.expenseRows.forEach((expense, idx) => {
    const presenceFields: (keyof SubmissionData2024Expense)[] = [
      'dateOfService',
      'billingProvider',
      'paymentAmount',
      'originalCharges',
      'discountAmount',
    ]
    presenceFields.forEach((field) => {
      const value = expense[field]
      if (typeof value == 'undefined' || value === null || value === '') {
        errors.push({
          field: `expenseRows[${idx}].${field}`,
          message: 'This field is required'
        })
      }
    })
  })
  
  return errors
}

export function validateMedicalInformationReleaseData2024(data: SubmissionData2024CHM['medicalInformationReleaseData']): ValidationError[] {
  const errors: ValidationError[] = []
  if (!data) { return errors }
  
  const presenceFields: (keyof SubmissionData2024CHM['medicalInformationReleaseData'])[] = [
    'patientName',
    'memberNumber',
    'patientDOB',
    'ssnLast4',
    'address',
    'phoneNumber',
    'consent',
    'signatureData',
    'printedNameOfPatient',
    'date',
  ]
  presenceFields.forEach((field) => {
    const value = data[field]
    if (typeof value == 'undefined' || value === null || value === '') {
      errors.push({
        field,
        message: 'This field is required'
      })
    }
  })
  
  if (present(data.printedNameOfAuthorizedRepresentative)) {
    if (!present(data.authorizedRepresentativeRelationship)) {
      errors.push({
        field: 'authorizedRepresentativeRelationship',
        message: 'This field is required'
      })
    }
  }
  
  return errors
}

export function validateMaternityVerificationFormData2024(data: SubmissionData2024CHM['maternityVerificationFormData']): ValidationError[] {
  const errors: ValidationError[] = []
  if (typeof data?.isMaternityIncident === 'undefined') {
    errors.push({
      field: 'isMaternityIncident',
      message: 'Please choose an option'
    })
  }

  if (!data?.isMaternityIncident) {
    return errors
  }
  
  const presenceFields: (keyof SubmissionData2024CHM['maternityVerificationFormData'])[] = [
    'patientName',
    'memberNumber',
    'isIVF',
    'dueDate',
    'deliveryLocation',
    'chosenHospital',
    'consentMemberName',
    'signatureData',
    'consentDate',
  ]
  presenceFields.forEach((field) => {
    const value = data[field]
    if (typeof value == 'undefined' || value === null || value === '') {
      errors.push({
        field,
        message: 'This field is required'
      })
    }
  })
  
  return errors
}
