import { Navigate, useNavigate, useParams } from "react-router"
import { useAppSelector } from "../../hooks/reduxToolkit"
import { useEffect, useState } from "react"
import { NotFound } from "../../screens/notFound"
import { SplashScreen } from "../../screens/splashScreen"

export function ExpenseRedirect() {
  const navigate = useNavigate()
  const { id } = useParams()

  const expense = useAppSelector((state) => state.expenses.expenses.find((e) => e.id == id))
  
  const [timedOut, setTimedOut] = useState(false)
  useEffect(() => {
    const timeout =setTimeout(() => {
      setTimedOut(true)
    }, 1000)
    
    return () => clearTimeout(timeout)
  }, [])
  
  if (!expense) {
    if (timedOut) {
      return <NotFound />
    }
    // Give some time for the app to sync down expenses from the server
    return <SplashScreen />
  }

  if (expense.incident_id) {
    return <Navigate to={`/incidents/${expense.incident_id}`} state={{ expense_id: expense.id }} />
  }

  return <Navigate to={`/expenses`} state={{ expense_id: expense.id }} />
}
