import { ProviderModel } from "../../reduxToolkit/providersSlice"
import CreatableSelect, { CreatableProps } from 'react-select/creatable'
import { GroupBase, SingleValue } from "react-select"
import { useAppSelector } from "../../hooks/reduxToolkit"

import './providerSelect.scss'

export type ProviderOption = {
  id?: string | null
  value: string
}

export interface ProviderSelectProps extends CreatableProps<ProviderOption, false, GroupBase<ProviderOption>> {
  className?: string
  disabled?: boolean
  value?: ProviderOption | null
  onChange?: (provider: ProviderOption | null) => void
}

export function ProviderSelect({
  className,
  disabled,
  value,
  onChange,
  ...props
}: ProviderSelectProps) {
  const providers: ProviderModel[] = useAppSelector((s) => s.providers?.providers || [])
  
  const renderLabel = (p: ProviderModel) => {
    const [street, ...rest] = p.street_address?.split(',') || []
    const formattedAddress = `${street}\n${rest.join(',').trim()}`.trim()
    
    return <p className='provider-label'>
      {p.name}<br/>
      <pre className='text-muted provider-label__address'>{formattedAddress}</pre>
    </p>
  }

  const options = providers.map((p) => ({
    id: p.id,
    label: renderLabel(p),
    value: p.name
  }))

  let selectedOption: ProviderOption | null = null
  if (value) {
    selectedOption = options.find(o => o.value === value.value) || null
    if (!selectedOption) {
      selectedOption = {
        value: value.value,
      }
    }
  }

  return <CreatableSelect<ProviderOption>
    {...props}
    className={className}
    classNamePrefix="react-select"
    isClearable
    isDisabled={disabled}
    options={options}
    value={selectedOption ? {
      label: selectedOption.value,
      value: selectedOption.value
    } : null}
    onChange={(newValue: SingleValue<ProviderOption>) => {
      console.log('newValue', newValue)
      onChange?.({
        value: newValue?.value || '',
        id: newValue?.id
      })
    }}
    placeholder="Select or enter provider..."
    formatCreateLabel={(inputValue: string) => `New provider: "${inputValue}"`}
  />
} 
