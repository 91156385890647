import { createAsyncThunk } from "@reduxjs/toolkit"
import { deleteProvider, ProviderModel } from "../providersSlice"
import { RootState } from "../store"
import { updateExpense } from "../expensesSlice"

export const mergeDuplicateProvider = createAsyncThunk('providers/mergeDuplicateProvider',
  async (payload: { provider: ProviderModel, duplicateProvider: ProviderModel }, {getState, dispatch}) => {
    const { provider, duplicateProvider } = payload

    const state = getState() as RootState
    
    const now = new Date().toISOString()
    
    // Find all expenses that are associated with the duplicate provider
    const duplicateExpenses = state.expenses.expenses.filter((e) => e.provider_id === duplicateProvider.id)

    // Update the provider_id for each of those expenses to be the provider.id
    for (const expense of duplicateExpenses) {
      dispatch(updateExpense({id: expense.id, provider_id: provider.id, updated_at: now}))
    }

    // Delete the duplicate provider
    dispatch(deleteProvider({id: duplicateProvider.id, updated_at: now, deleted_at: now}))
  }
)
