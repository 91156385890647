
/**
 * Marks certain fields as not null.
 * If the second type parameter is omitted, marks all keys as not null.
 */
export type NotNull<T extends { [key: string]: any }, key extends keyof T = keyof T> =
  T &
  {
    [k in key]-?: Exclude<T[k], null | undefined>
  }
  
export function isNotNull<T extends { [key: string]: any }, keys extends keyof T>(value: T, keys: keys[]): value is NotNull<T, keys> {
  return keys.every(key => value[key] !== null && value[key] !== undefined)
}

export type DeepNullable<T> = {
  [K in keyof T]: T[K] extends object ? (DeepNullable<T[K]> | null) : (T[K] | null)
}

export type DeepPartialNullable<T> = {
  [K in keyof T]?: T[K] extends object ? (DeepPartialNullable<T[K]> | null) : (T[K] | null)
}
