import { present } from "async-toolbox"
import { ExpenseModel, isSubmittedExpense, isCompleteExpense, isCompleteExceptForIncidentID, CompleteExpense, updateExpense } from "../../app/reduxToolkit/expensesSlice"
import { RootState } from "../../app/reduxToolkit/store"
import { VirtualToDo } from "./types"
import { formatDateInTimeZone } from "../formatDateInTimeZone"
import { calculateDueDate, chooseSubmissionType } from "../rules/chooseSubmissionType"
import { formatCurrency } from "../formatCurrency"
import { Customizations, selectCustomizations } from "../../app/reduxToolkit/selectors/customizations"
import Decimal from "decimal.js"
import { cloneDeep } from "lodash"

export function * createToDosForExpense(s: RootState, expense: ExpenseModel): Generator<VirtualToDo> {
  if (!isCompleteExpense(expense) && !isSubmittedExpense(expense)) {
    const dueDate = expense.date ? calculateDueDate(expense.date) : undefined

    yield {
      key: `expense/${expense.id}/fill`,
      title: isCompleteExceptForIncidentID(expense) ?
        `Assign this expense to an incident: ${formatExpenseInline(expense)}` :
        `Add missing data for this expense: ${formatExpenseInline(expense)}`,
      record_id: expense.id,
      record_type: 'expense',
      todo_type: 'fill',
      dueDate,
      action: {
        type: 'navigate',
        route: `/expenses/${expense.id}/edit`,
      }
    }
    return
  }
  
  const customizations = selectCustomizations(s)
  
  let checks: Array<(s: RootState, expense: CompleteExpense, customizations: Customizations) => VirtualToDo | void> = [
    checkItemizedBillTodo,
    checkPrepaymentAgreementPDF,
    checkCompletePaymentTodo
  ]
  
  for (const check of checks) {
    const todo = check(s, expense, customizations)
    if (todo) {
      yield todo
    }
  }
}

function checkItemizedBillTodo(s: RootState, expense: CompleteExpense, customizations: Customizations): VirtualToDo | void {
  if (expense.is_prepayment_agreement) {
    // This is handled by checkPrepaymentAgreementPDF
    return
  }
  
  if (isSubmittedExpense(expense)) {
    // Not applicable for submitted expenses
    return
  }
  
  const incident = s.incidents.incidents?.find((i) => i.id === expense.incident_id)
  if (!incident) { return }

  const submissionType = chooseSubmissionType(
    {
      incident,
      incidentExpenses: s.expenses.expenses.filter((e) => e.incident_id === expense.incident_id),
      incidentSubmissions: s.submissions.submissions.filter((s) => s.incident_id === expense.incident_id),
    }, 
    customizations
  )
  
  // itemized-bill: needed if
  //  1. expense is not yet submitted
  //  2. no attachments yet OR no itemized-bill attachments
  //  3. incident is going to CHM or CHM-Addon
  if (
    (submissionType === 'CHM' || submissionType === 'CHM-addon') &&
    !present(s.attachments.attachments?.find((a) => a.record_id === expense.id && a.purpose === 'itemized-bill'))
  ) {
    return {
      key: `expense/${expense.id}/itemized-bill`,
      title: `Get an Itemized Bill from ${expense.provider}`,
      record_id: expense.id,
      record_type: 'expense',
      todo_type: 'itemized-bill',
      action: {
        type: 'navigate',
        route: `/expenses/${expense.id}/edit`,
      }
    }
  }

  // receipt: needed if
  //  1. expense is not yet submitted
  //  2. no attachments yet
  //  3. incident is going to HRA and this expense was paid from personal funds
  if (
    submissionType === 'HRA' && !expense.paid_with_hra &&
    !present(s.attachments.attachments?.find((a) => a.record_id === expense.id))
  ) {
    return {
      key: `expense/${expense.id}/receipt`,
      title: `Attach a receipt from ${expense.provider}`,
      record_id: expense.id,
      record_type: 'expense',
      todo_type: 'receipt',
      action: {
        type: 'navigate',
        route: `/expenses/${expense.id}/edit`,
      }
    }
  }
}

function checkPrepaymentAgreementPDF(s: RootState, expense: CompleteExpense, customizations: Customizations): VirtualToDo | void {
  if (isSubmittedExpense(expense)) {
    // Not applicable for submitted expenses
    return
  }
  
  // prepayment-agreement-pdf: needed if
  //  1. expense is not yet submitted
  //  2. no attachments yet
  //  3. expense is a pre-payment agreement
  if (
    expense.is_prepayment_agreement &&
    !present(s.attachments.attachments?.find((a) => a.record_id === expense.id))
  ) {
    return {
      key: `expense/${expense.id}/prepayment-agreement-pdf`,
      title: `Upload your pre-payment agreement from ${expense.provider}`,
      record_id: expense.id,
      record_type: 'expense',
      todo_type: 'prepayment-agreement-pdf',
      action: {
        type: 'navigate',
        route: `/expenses/${expense.id}/edit`,
      }
    }
  }
}

function checkCompletePaymentTodo(s: RootState, expense: CompleteExpense, customizations: Customizations): VirtualToDo | void {
  if (!isSubmittedExpense(expense)) {
    // Not applicable for unsubmitted expenses
    return
  }
  
  // When we do the migration, some expenses in the store will have is_fully_paid be undefined.
  // We don't want to show the complete-payment todo for those.
  if (expense.is_fully_paid !== false) {
    return
  }
  
  // how much is left to pay?
  let remainingOwedAmount: Decimal | undefined = undefined
  if (expense.payment_history) {
    remainingOwedAmount = expense.payment_history.payments.reduce((acc, payment) => {
      return acc.minus(payment.amount)
    },
      // Legacy naming issue: paidAmount tracks the total post-discount amount that the user is on the hook for.
      new Decimal(expense.paidAmount)
    )
  }
  
  const now = new Date().toISOString()
  const todaysDateUtc = formatDateInTimeZone(new Date(), { timeZone: 'UTC', format: 'yyyy-MM-dd' })
  
  const newPaymentHistory: ExpenseModel['payment_history'] = cloneDeep(expense.payment_history || { _version: '2024-12-24', payments: [] })
  newPaymentHistory.payments.push({
    amount: remainingOwedAmount?.toFixed(2) || expense.paidAmount,
    date: todaysDateUtc
  })
  
  return {
    key: `expense/${expense.id}/complete-payment`,
    title: remainingOwedAmount ?
      `Pay the remaining ${formatCurrency(remainingOwedAmount)} to ${expense.provider}` :
      `Pay the remaining amount to ${expense.provider}`,
    record_id: expense.id,
    record_type: 'expense',
    todo_type: 'complete-payment',
    action: {
      type: 'dispatch',
      action: updateExpense({
        id: expense.id,
        updated_at: now,
        is_fully_paid: true,
        payment_history: newPaymentHistory,
      }),
      confirm: {
        title: `Did you pay your bill to ${expense.provider}?`,
        body: `If you paid the full amount, click "yes".  If you got on a payment plan, you should still click "yes".`,
      }
    }
  }
}

function formatExpenseInline(expense: ExpenseModel): string {
  let { date, provider, paidAmount } = expense
  if (present(provider)) {
    provider = `at "${provider}"`
  }
  if (present(date)) {
    date = `on ${formatDateInTimeZone(date, { format: 'MMM d' })}`
  }
  if (present(paidAmount)) {
    paidAmount = `${formatCurrency(paidAmount)}`
  } else {
    paidAmount = 'unknown'
  }
  return [paidAmount, date, provider].filter(present).join(' ')
}
