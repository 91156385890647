import { useNavigate } from 'react-router-dom';
import KeyboardIcon from '../../icons/keyboard_icon.svg'
import { useBlobOcr } from '../hooks/useBlobOcr';
import { useSentry } from '../providers/sentry';
import { NewExpenseRowFormProps } from './forms/newExpenseRowForm';
import { SnapAPhoto } from './snapAPhoto';

import './quickAddExpense.scss'


export type QuickAddExpenseProps = NewExpenseRowFormProps

export function QuickAddExpense(props: QuickAddExpenseProps) {
  return <>
    <div className='d-none d-lg-block'>
      <QuickAddExpenseDesktop {...props} />
    </div>
    <div className='d-lg-none'>
      <QuickAddExpenseMobile {...props} />
    </div>
  </>
}

function QuickAddExpenseDesktop({onInserted, prefilledData, incidentId, ...formProps}: QuickAddExpenseProps) {
  const {captureException} = useSentry()
  const navigate = useNavigate()

  const [result, {error, reset}, createExpenseFromBlob] = useBlobOcr({
    prefilledExpense: {
      incident_id: incidentId,
      ...prefilledData,
    },
    onCompleted(result) {
      if (result.expense) {
        onInserted?.(result.expense, null)
      } else {
        captureException(new Error('No expense returned from blob OCR'))
      }
    },
  })

  return <div className="row quick-add quick-add__desktop">
    <div className="col-12 col-lg-5 quick-add__snap">
        <h5>Upload receipt or&nbsp;itemized&nbsp;bill</h5>
        <SnapAPhoto onUpload={createExpenseFromBlob} />
        <span className='mt-1 underline'>(PDF Or Image)</span>
        {error && <div className="alert alert-danger" role="alert">
          Something went wrong! {error.message || error.toString()}
        </div>}
      </div>

    <div className='col-12 col-lg-1 quick-add__separator'>
      <span className="quick-add__separator-or">OR</span>
    </div>

    <div className="col-12 col-lg-6 quick-add__manual">
      <h5>Enter new expense&nbsp;manually</h5>
      <button className={`btn btn-primary quick-add__keyboard-button`}
        onClick={() => navigate('/expenses/new', { state: { incident_id: incidentId, prefilledData: prefilledData } })}>
          <img src={KeyboardIcon} alt="Enter new expense manually" />
      </button>
    </div>
  </div>
}

function QuickAddExpenseMobile({onInserted, prefilledData, incidentId, ...formProps}: QuickAddExpenseProps) {
  const {captureException} = useSentry()
  const navigate = useNavigate()
  const [result, {error, reset}, createExpenseFromBlob] = useBlobOcr({
    prefilledExpense: {
      incident_id: incidentId,
      ...prefilledData,
    },
    onCompleted(result) {
      if (result.expense) {
        onInserted?.(result.expense, null)
      } else {
        captureException(new Error('No expense returned from blob OCR'))
      }
    },
  })

  return <div className="row quick-add quick-add__mobile">
    <div className="col-12 quick-add__snap">
      <h6>Upload receipt or&nbsp;itemized&nbsp;bill</h6>
      <SnapAPhoto onUpload={createExpenseFromBlob} />
      <span className='underline'>(PDF Or Image)</span>
        {error && <div className="alert alert-danger" role="alert">
          Something went wrong! {error.message || error.toString()}
        </div>}
    </div>
    <div className='col-12 quick-add__separator'>
      <span className="quick-add__separator-or">OR</span>
    </div>
    <div className="col-12 quick-add__manual">
      <h6>Enter new expense manually</h6>
      <button className={`btn btn-primary quick-add__keyboard-button`}
        onClick={() => {navigate('/expenses/new', { state: { incident_id: incidentId, prefilledData: prefilledData } })}}>
          <img src={KeyboardIcon} alt="Enter new expense manually" />
      </button>
    </div>
  </div>
}
