
import { VirtualToDo } from "../../../lib/todos/types";
import { byDueDateAscendingNullsFirst } from "../../../lib/util/sort";
import { RootState } from "../store";
import { selectAllTodosReferencingIncident } from "./selectAllTodosReferencingIncident";

export function selectFirstTodoReferencingIncident(incidentId: string): (s: RootState) => VirtualToDo | undefined {
  return (s: RootState) => {
    return selectAllTodosReferencingIncident(incidentId)(s)
      .sort(byDueDateAscendingNullsFirst)[0]
  }
}
