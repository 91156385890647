import type { ExpenseModel } from "../../app/reduxToolkit/expensesSlice"
import type { AppAction } from "../../app/reduxToolkit/store"

/**
 * Represents a ToDo that has not been persisted to the database.  These ToDos
 * are calculated based on the current app state.
 */
export interface VirtualToDo {
  key: string
  title: string

  record_id: string
  record_type: RecordsThatHaveTodos
  todo_type: string

  dueDate?: string

  /**
   * The list of todos that this todo depends on.  This one can't be completed until all
   * of the todos in this list are completed.
   */
  dependsOn?: TodoDependency[]

  action?: TodoAction
}

export type TodoDependency = {
  key: string,
  record_id: string,
  record_type: RecordsThatHaveTodos,
}

export type RecordsThatHaveTodos = 'expense' | 'incident' | 'submission' | 'advance'

export function raiseUnknownRecordType(recordType: never): never {
  throw new Error(`Record types cannot have TODOs: ${recordType}`)
}

export type TodoConfirm = string | {
  title: string,
  body?: string | null | undefined
}

export type TodoAction = {
  type: 'dispatch',
  confirm?: TodoConfirm,
  action: AppAction
} | {
  type: 'navigate',
  confirm?: TodoConfirm,
  route: string
  state?: any
}

export function raiseUnknownTodoAction(action: never): never {
  throw new Error(`Unknown ToDo action ${(action as any)?.type || action}`)
}
