import { useNavigate } from "react-router-dom"
import { ExpenseModel } from "../reduxToolkit/expensesSlice"

export function useNavigateToNewExpense() {
  const navigate = useNavigate()

  return (expense: ExpenseModel) => {
    // Did the new expense create a new provider?
    if (expense.provider && !expense.provider_id) {
      // Make sure we link the provider
      navigate(`/expenses/${expense.id}/providers/new`)
    } else if (expense.incident_id) {
      navigate(`/incidents/${expense.incident_id}`, { state: { expense_id: expense.id } })
    } else {
      navigate('/expenses', { state: { expense_id: expense.id } })
    }
  }
}
