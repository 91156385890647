import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { RequireIncidentProvided, requireIncident } from "../../wrappers/requireIncident"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxToolkit"
import { IncidentSelect } from "../../components/formComponents/incidentSelect"
import { BlobOCRResult, useBlobOcr } from "../../hooks/useBlobOcr"
import { useCallback, useEffect, useState } from "react"
import { deleteExpense, isCompleteExpense } from "../../reduxToolkit/expensesSlice"
import { updateIncident } from "../../reduxToolkit/incidentsSlice"
import { BlobUploadDropzone } from "../../components/blobUploadDropzone"
import { FilePreview } from "../../components/filePreview"
import { present } from "../../../lib/util/present"
import { ExpenseSpreadsheet } from "../../components/expenseSpreadsheet"

import './bulkAddExpenseToIncident.scss'

function BulkAddExpenseToIncident({incident}: RequireIncidentProvided) {

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  
  const [results, setResults] = useState<BlobOCRResult[]>([])
  const expenseIDs = results.map((r) => r.expense?.id).filter(present)
  const expenses = useAppSelector((state) => state.expenses.expenses.filter((e) => expenseIDs.includes(e.id)))

  const minExpenseDate = expenses.map((e) => e.date).sort()[0]
  useEffect(() => {
    // If any expense has a date earlier than the incident start date, update the incident start date
    if (minExpenseDate && minExpenseDate < incident.start_date) {
      dispatch(updateIncident({
        id: incident.id,
        updated_at: new Date().toISOString(),
        start_date: minExpenseDate,
      }))
    }
  }, [dispatch, incident.id, incident.start_date, minExpenseDate])

  const [_, {error, reset}, createExpenseFromBlob] = useBlobOcr({
    prefilledExpense: {
      incident_id: incident.id,
      patient_dob: incident.patient_dob,
      patient_name: incident.patient_name,
    },
    onCompleted(result) {
      if (result.expense) {
        setResults((results) => [...results, result])
      }
    }
  })

  const allExpensesScanned = expenses.every((s) => present(s.textract_job_completed_at))
  const allExpensesCompleted = expenses.every(isCompleteExpense)

  const cancel = useCallback((e?: React.MouseEvent, navigateTo?: string) => {
    e?.preventDefault();
    if (expenses.length > 0) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Delete all uploaded expenses?')) {
        const now = new Date().toISOString()
        for (const expense of expenses) {
          dispatch(deleteExpense({
            id: expense.id,
            updated_at: now,
            deleted_at: now,
          }))
        }
      }
    }

    navigate(navigateTo || `/incidents/${incident.id}`)
  }, [dispatch, expenses, incident.id, navigate])

  return <div className="bulk-add-expense">
    <div className="bulk-add-expense__header">
      <h1>Bulk Add Expense</h1>
      <button className={`btn btn-link`}
          onClick={cancel}>
        <i className="material-icons-outlined">close</i>
        <span className="visually-hidden">Cancel</span>
      </button>
    </div>
    <div className="col-12 col-md-6">
      <IncidentSelect
        id={`incident-select/${incident.id || ''}`}
        value={incident.id}
        blankOption={false}
        disabled={results.length > 0}
        onSelectNewIncident={() => navigate(`/bulkAddExpense`)}
        onSelect={(value) => {
          if (!incident.id && !value) return
          if (incident.id && !value) return navigate(`/bulkAddExpense`)

          if (value && 'id' in value && value.id !== incident.id) {
            cancel(undefined, `/incidents/${value}/bulkAddExpense`)
          }
        }}
        />
    </div>

    <div className="col-12 bulk-add-expense__upload">
      <div className="row">
        <div className="col-12 col-md-6">
          <BlobUploadDropzone maxFiles={100} onUpload={(params) => {
            // dispatch this in the background but allow the user to upload more files
            createExpenseFromBlob({
              ...params,
              purpose: 'itemized-bill',
            })
          }}>
            <div className="card">
              <div className="card-body">
                <p className="card-text">
                  <i className='material-icons-outlined'>photo_camera</i><br />
                  <span>Upload a PDF or image</span><br />
                </p>
              </div>
            </div>
          </BlobUploadDropzone>
        </div>
      </div>
      <div className="row d-none d-md-flex bulk-add-expense__upload-preview">
          {results.map((result, index) => {
            return <div className="col-2" key={index}>
              <h6>{index + 1}.</h6>
              <FilePreview file={result.file} />
            </div>
          })}
      </div>
    </div>

    <div className="col-12 bulk-add-expense__spreadsheet">
      <ExpenseSpreadsheet expenses={expenses} />
    </div>

    <div className="col-12 bulk-add-expense__done">
      <Link to={`/incidents/${incident.id}`}
        className={`btn btn-primary ${!allExpensesScanned && 'disabled'}`}
        onClick={(e) => {
          if (!allExpensesCompleted) {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('Some of your expenses are missing information. Do you want to finish later?')) {
              e.preventDefault()
            }
          }
        }}>
          Done
      </Link>
    </div>
  </div>
}

export default requireIncident(BulkAddExpenseToIncident)

