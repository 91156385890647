import { SupabaseClient } from "@supabase/supabase-js"
import { downloadFile } from "./downloadFile"
import { BlobRow } from "../../types/supabase"

export async function downloadBlob(blob: BlobRow, { client }: { client: SupabaseClient }) {
  const file = await client.storage.from('wcc-hra-calculator-private').download(blob.file_full_path)
  if (file.error) { throw new Error(file.error.message, { cause: file.error }) }

  downloadFile(blob.file_name, file.data)
}
