/**
 * Converts a string from snake_case or camelCase to Title Case with spaces
 * Example: "itemized_bill" -> "Itemized Bill"
 * Example: "receiptPhoto" -> "Receipt Photo"
 */
export function humanize(str: string): string {
  if (!str) return '';
  
  // Handle snake_case
  const withSpaces = str.replace(/(_|-)/g, ' ');
  
  // Handle camelCase
  const separated = withSpaces.replace(/([A-Z])/g, ' $1').toLowerCase();
  
  // Capitalize first letter of each word
  return separated
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .trim();
} 
