import { useState } from "react";
import { present } from "../../lib/util/present";
import { useAsyncEffect } from "./useAsyncEffect";
import { useAsyncAction } from "./useAsyncAction";
import { useClient } from "../providers/supabase";
import { useUser } from "./useUser";
import { selectOrganizationForUser } from "../../lib/queries/selectOrganizationForUser";

interface RedeemCodeData {
  isForPersonalUse?: boolean

  organizationName: string
  organizationSize: string
  hasHra?: boolean
  householdSize?: string
  incidentCount?: string
}

export function useValidatePromoCode(code: string | null | undefined) {
  const [isValid, setIsValid] = useState(false)
  const validateStatus = useAsyncEffect(async () => {
    if (!present(code)) { 
      setIsValid(false)
      return
    }
    
    // TODO: make a request to the server to validate the promo code
    const valid = code.toUpperCase() === 'BCQR24'
    setIsValid(valid)
  }, [code])
  
  const client = useClient()
  const user = useUser()
  
  const [redeemCodeResult, redeemCodeFn] = useAsyncAction(async (data: RedeemCodeData) => {
    if (!present(code)) { throw new Error('No promo code provided') }
    
    let organization = user?.id ? await selectOrganizationForUser(client, { id: user?.id, email: null }) : null
    if (!organization) {
      if (!data.isForPersonalUse) {
        // insert a new organization with the data
        const orgResp = await client.from('organizations')
          .insert({
            name: data.organizationName,
            created_by_user_id: user?.id,
            // Intentionally not setting any email domains - we update this manually when we get a contract signed.
            email_domains: [],
          })
          .select('*')
          .single()
        if (orgResp.error) {
          throw new Error(orgResp.error.message)
        }
        organization = orgResp.data
      }
    }

    // Insert a promo code redemption record
    const redemptionResp = await client.from('promo_code_redemptions')
      .insert({
        code: code,
        organization_id: organization?.id,
        user_id: user?.id,
        additional_data: (data as { [key: string]: any })
      })
    
    if (redemptionResp.error) {
      throw new Error(redemptionResp.error.message)
    }
  }, [code, user?.id])
  
  return [
    isValid,
    {
      loading: validateStatus.loading,
      redeeming: redeemCodeResult.loading,
      error: validateStatus.error || redeemCodeResult.error
    },
    redeemCodeFn
  ] as const
}
