import { useState, useCallback, useMemo } from "react"
import FileUploadIcon from '../../icons/file_upload_icon.svg'
import * as bootstrap from "bootstrap";
import Dropzone from "react-dropzone"
import usePortal from "react-useportal"
import { useConfirm } from "../providers/confirmDialog";
import { useAsyncAction } from "../hooks/useAsyncAction";
import { createPdfFromImages } from "../../lib/pdf/createPdfFromImages";
import { formatDateInTimeZone } from "../../lib/formatDateInTimeZone";
import { PdfPreview } from "./preview/pdfPreview";
import { createBlob } from "../../lib/supabase/createBlob";
import { useAppSelector } from "../hooks/reduxToolkit";
import { useClient } from "../providers/supabase";
import { AttachmentPurpose, BlobRow } from "../../types/supabase";
import { assert } from "../../lib/util/assert";

export type SnapAPhotoOnUploadParams = {blob: BlobRow, file: File, purpose: AttachmentPurpose}

interface SnapAPhotoProps {
  onUpload: (params: SnapAPhotoOnUploadParams) => void
}

import './snapAPhoto.scss'

export function SnapAPhoto({onUpload, children}: React.PropsWithChildren<SnapAPhotoProps>) {
  const { Portal } = usePortal()
  const [droppedFiles, setDroppedFiles] = useState<File[]>([])
  const [isItemizedBill, setIsItemizedBill] = useState(true)
  const membership_id = useAppSelector((s) => s.membership.membershipId)
  const client = useClient()
  
  const [confirm, lastConfirm] = useConfirm()
  
  const uniqueId = useMemo<number>(() => Math.floor(Math.random() * 10_000), [])
  const domId = `modal-snap-a-photo-${uniqueId}`
  
  const uploadedFileIsPdf = droppedFiles.length === 1 && (droppedFiles[0].type === 'application/pdf' || droppedFiles[0].name.endsWith('.pdf'))
  
  const showModal = useCallback(() => {
    const modalElem = document.getElementById(domId)
    const modal = bootstrap.Modal.getInstance(modalElem!) || new bootstrap.Modal(modalElem!, {
      backdrop: 'static',
      keyboard: false,
    })
    modal?.show()
  }, [domId])
  
  const hideModal = useCallback(() => {
    setDroppedFiles([])
    setIsItemizedBill(false)
    resetUploadResult()

    const modalElem = document.getElementById(domId)
    const modal = bootstrap.Modal.getInstance(modalElem!)
    modal?.hide()
  }, [domId])
  
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setDroppedFiles((f) => [...f, ...acceptedFiles])
    
    showModal()
  }, [showModal])

  const [uploadResult, doFileUpload, resetUploadResult] = useAsyncAction(async () => {
    if (droppedFiles.length === 0) {
      hideModal()
      return
    }
    
    let file: File
    if (isItemizedBill) {
      if (uploadedFileIsPdf) {
        // It's already a pdf
        file = droppedFiles[0]
      } else {
        const pdfFile = await createPdfFromImages(droppedFiles)
        file = new File([pdfFile], `${formatDateInTimeZone(new Date(), { format: 'MM-dd-yyyy' })}_itemized-bill.pdf`, { type: 'application/pdf' })
      }
    } else {
      // If it's not an itemized bill, we only have one file
      file = droppedFiles[0]
    }
    
    const blob = await createBlob(
        file,
        {
          membership_id
        },
        {
            client
        })

    onUpload({blob, file, purpose: isItemizedBill ? 'itemized-bill' : 'receipt'})
    hideModal()
  }, [droppedFiles, isItemizedBill])


  return <div className="snap-a-photo">
    <DropzoneWidget onDrop={onDrop} allowPdf={true}>
      {children}
    </DropzoneWidget>
    
    <Portal>
      <div className="modal snap-a-photo__modal modal-lg fade"
        id={domId}
          aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5">Snap a Photo</h2>
              <button type="button" className="btn-close"
                aria-label="Close"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (droppedFiles.length === 0) {
                    hideModal()
                  } else {
                    confirm({
                      title: 'Are you sure you want to cancel?',
                    message: 'Your uploaded files will be discarded.',
                    actions: [
                      {
                        title: 'Yes, discard',
                        isConfirm: true,
                        onClick: () => {
                          setDroppedFiles([])
                          setIsItemizedBill(false)
                          hideModal()
                        }
                      },
                      {
                        title: 'No, keep uploading',
                        isCancel: true,
                      }
                    ]
                    })
                  }
                }}
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <form>
                    <div className="form-group">
                      <label>Is this a receipt or itemized bill?</label><br/>
                      <div className='form-check form-check-inline'>
                        <input
                          type='radio'
                          className={`form-check-input`}
                          id={`is-receipt-${uniqueId}`}
                          name={`receipt-or-bill-${uniqueId}`}
                          value='receipt'
                          checked={!isItemizedBill}
                          onChange={(e) => {
                            setIsItemizedBill(!e.target.checked)
                          }}
                        />
                        <label className='form-check-label' htmlFor={`is-receipt-${uniqueId}`}>
                          Receipt
                        </label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          type='radio'
                          className={`form-check-input`}
                          id={`is-itemized-bill-${uniqueId}`}
                          name={`receipt-or-bill-${uniqueId}`}
                          value='bill'
                          checked={isItemizedBill}
                          onChange={(e) => {
                            setIsItemizedBill(e.target.checked)
                          }}
                        />
                        <label className='form-check-label' htmlFor={`is-itemized-bill-${uniqueId}`}>
                          Itemized Bill
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row mt-2">
                {droppedFiles.map((file, index) =>
                  <div className="col-12" key={index}>
                    {file.type === 'application/pdf' ?
                      <PdfPreview file={file} /> :
                      <img src={URL.createObjectURL(file)}
                        style={{maxWidth: '100%'}}
                        alt='preview' />}
                  </div>
                )}
              </div>
              {isItemizedBill && !uploadedFileIsPdf && <div className="row mt-2">
                <div className="col-12 d-flex flex-column align-items-center">
                  <DropzoneWidget onDrop={onDrop} allowPdf={false}>
                    <button className="btn btn-outline-primary">+ Upload Another Page</button>
                  </DropzoneWidget>
                </div>
              </div>}
              
              {uploadResult.error && <div className="row">
                <div className="col-12">
                  <p className="text-danger">{uploadResult.error.message}</p>
                </div>
              </div>}
              
              {uploadResult.loading && <div className="row">
                <div className="col-12">
                  <div className="loading-bar w-100" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>}
            </div>
            <div className="modal-footer">
              <button type="button" className={`btn btn-primary ${droppedFiles.length === 0 ? 'disabled' : ''} ${uploadResult.loading ? 'disabled' : ''}`}
                disabled={droppedFiles.length === 0 || uploadResult.loading}
                onClick={doFileUpload}>
                  Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  </div>
}

function DropzoneWidget({onDrop, allowPdf, children}: {onDrop: (files: File[]) => void, allowPdf: boolean, children?: React.ReactNode}) {
  children = children || <img src={FileUploadIcon} alt="Upload Receipt" />
  
  return <div className="dropzone">
    <Dropzone onDrop={onDrop} maxFiles={1}
        accept={allowPdf ? {
          'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.tif'],
          'application/pdf': ['.pdf']
        } : {
          'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.tif']
        }}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="dropzone__content">
            {children}
          </div>
        </div>
      )}
    </Dropzone>
  </div>
}
