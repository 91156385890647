import { useState, useEffect } from "react"
import { GooglePlaceResult, searchPlacesNew } from "../../lib/google/places"
import { useAsyncEffect } from "./useAsyncEffect"

export function useGooglePlacesSearch(query: string | null | undefined, options: { limit: number } = { limit: 3 }, dependencies?: React.DependencyList) {
  const [results, setResults] = useState<GooglePlaceResult[]>()

  const state = useAsyncEffect(async () => {
    if (!query) {
      setResults([])
      return
    }
    
    const results = await searchPlacesNew(query, options)
    setResults(results.places)
  }, dependencies || [query])
  
  return [results, state] as const
}
