import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks/reduxToolkit';
import { NotFound } from '../../screens/notFound';
import { ExpenseTable } from '../../components/expenseTable';

import './show.scss';
import { ExpenseModel } from '../../reduxToolkit/expensesSlice';

export function ProviderShow() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const provider = useAppSelector((state) => state.providers?.providers.find((p) => p.id === id));
  
  const expensesWithoutItemizedBills = useAppSelector((state) => {
    const allExpenses = state.expenses.expenses
      .filter((e) => e.provider_id === id)
    
    const expensesWithoutItemizedBills: ExpenseModel[] = []
    for(const expense of allExpenses) {
      if (state.attachments?.attachments.find((a) => a.record_id === expense.id && a.table_name === 'expenses' && a.purpose === 'itemized-bill')) {
        continue
      }
      expensesWithoutItemizedBills.push(expense)
    }
    return expensesWithoutItemizedBills
  })
  
  if (!provider) {
    return <NotFound />;
  }
  
  let googleMapsUrl = undefined
  if (provider.street_address) {
    googleMapsUrl = `https://www.google.com/maps/search/${provider.street_address}`
  }

  const [street, ...rest] = provider.street_address?.split(',') || []
  const formattedAddress = provider.street_address ? `${street}\n${rest.join(',').trim()}` : undefined

  return (
    <div className="container provider-show">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1>{provider.name}</h1>
            <button 
              className="btn btn-outline-primary"
              onClick={() => navigate(`/providers/${id}/edit`)}
            >
              Edit
            </button>
          </div>

          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Contact Information</h5>
              <div className="row">
                <div className="col-3 provider-show__contact-info-label">Phone</div>
                <div className="col-7 provider-show__contact-info">
                  {provider.billing_phone}
                </div>
                <div className="col-2 provider-show__contact-info-icon">
                  {provider.billing_phone && <a href={`tel:${provider.billing_phone}`}>
                    <i className="material-icons">phone</i>
                  </a>}
                </div>
              </div>

              <div className="row">
                <div className="col-3 provider-show__contact-info-label">Email</div>
                <div className="col-7 provider-show__contact-info">
                  {provider.billing_email}
                </div>
                <div className="col-2 provider-show__contact-info-icon">
                  {provider.billing_email && <a href={`mailto:${provider.billing_email}`}>
                    <i className="material-icons">mail</i>
                  </a>}
                </div>
              </div>

              <div className="row">
                <div className="col-3 provider-show__contact-info-label">Website</div>
                <div className="col-9 provider-show__contact-info">
                  {provider.website && <a href={provider.website} target="_blank" rel="noopener noreferrer">
                    {provider.website}
                  </a>}
                </div>
              </div>

              <div className="row">
                <div className="col-3 provider-show__contact-info-label">Address</div>
                <div className="col-7 provider-show__contact-info">
                  {formattedAddress &&
                    <pre className="mb-0">{formattedAddress}</pre>
                  }
                </div>
                <div className="col-2 provider-show__contact-info-icon">
                  {googleMapsUrl && <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
                    <i className="material-icons">public</i>
                  </a>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="row">
        {expensesWithoutItemizedBills.length > 0 && <>
          <h5>Expenses Missing Itemized Bills:</h5>
          <ExpenseTable expenses={expensesWithoutItemizedBills} displayOptions={{ hideProvider: true }} />
        </>}
      </div>
    </div>
  );
} 
