import Decimal from "decimal.js"

export const LIMITS_2025 = {
  'qualifying_amount': new Decimal(1250),
  'personal_responsibility_amount': new Decimal(1250),
  'maternal_personal_responsibility_amount': new Decimal(2500)
} as const;

export const LIMITS_2024 = {
  'qualifying_amount': new Decimal(1000),
  'personal_responsibility_amount': new Decimal(1000),
  'maternal_personal_responsibility_amount': new Decimal(1500),
} as const;


export function getCurrentLimits() {
  const year = new Date().getFullYear()
  if (year <= 2024) {
    return LIMITS_2024
  }
  return LIMITS_2025
}

export function getLimits(date: Date) {
  const year = date.getFullYear()
  if (year <= 2024) {
    return LIMITS_2024
  }
  return LIMITS_2025
}
