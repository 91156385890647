/* eslint-disable react-hooks/exhaustive-deps */
import { DependencyList, useCallback, useEffect, useState } from 'react'
import { useSentry } from '../providers/sentry';

type Destructor = () => void;
type AsyncEffectCallback = () => Promise<void | Destructor>;

export function useAsyncEffect(effect: AsyncEffectCallback, deps?: DependencyList) {
  const [loading, setLoading] = useState(true)
  const [refreshing, setRefreshing] = useState(false)
  const [error, setError] = useState<Error>()
  const Sentry = useSentry()

  useEffect(useCallback(() => {
    // restart the callback sequence
    setError(undefined)
    setRefreshing(true)

    let destructor: Destructor | undefined = undefined

    // init the callback
    effect()
      .then(
        (voidOrDestructor) => {
          // success!
          setLoading(false)
          setRefreshing(false)
          setError(undefined)
          if (voidOrDestructor) {
            destructor = voidOrDestructor
          }
        },
        (err) => {
          setLoading(false)
          setRefreshing(false)
          setError(err)
          Sentry.captureException(err)
        })

    return () => {
      if (destructor) {
        destructor()
      }
    }
  }, deps || []), deps || [])

  return {loading, refreshing, error} as const
}
