import {createBrowserRouter, RouterProvider, ScrollRestoration, Outlet, useParams, useNavigate} from 'react-router-dom'
import { wrapCreateBrowserRouter } from '@sentry/react';

import { Homepage } from './app/routes/homepage';
import { TopNav } from './app/components/topNav'
import { Expenses } from './app/routes/expenses';
import { NewExpense } from './app/routes/expenses/newExpense';
import { EditExpense } from './app/routes/expenses/editExpense';
import EditProfile from './app/routes/editProfile';
import IncidentsShow from './app/routes/incidents/show';
import ChangePassword from './app/routes/changePassword';
import { NotFound } from './app/screens/notFound';
import { Files } from './app/routes/files';
import EditDependents from './app/routes/editDependents';
import { Help } from './app/routes/help';
import IncidentsSubmit from './app/routes/incidents/submit';
import SubmitHome from './app/routes/incidents/submit/home';
import SubmitShow from './app/routes/incidents/submit/show';
import { BulkAddExpense } from './app/routes/bulkAddExpense';
import BulkAddExpenseToIncident from './app/routes/incidents/bulkAddExpenseToIncident';
import { Todos } from './app/routes/todos';
import SubmitPersonalResponsibility from './app/routes/incidents/submit/submitPersonalResponsibility';
import { DeleteAccount } from './app/routes/deleteAccount';
import EditSettings from './app/routes/editSettings';
import { ErrorFallback } from './app/components/error-boundary';
import { useEffect, useMemo, useState } from 'react';
import { IncidentsIndex } from './app/routes/incidents';
import { IncidentCreate } from './app/routes/incidents/incidentCreate';
import AdvanceRequestCreate from './app/routes/incidents/advanceRequest/advanceRequestCreate';
import { AdvanceShow } from './app/routes/incidents/advance/show';
import AlertBanner from './app/components/alertBanner';
import { Notifications } from './app/routes/notifications';
import { Test } from './app/routes/test';
import { useAppSelector } from './app/hooks/reduxToolkit';
import { NewProvider } from './app/routes/providers/newProvider';
import { ProviderShow } from './app/routes/providers/show';
import { EditProvider } from './app/routes/providers/editProvider';
import { ExpenseRedirect } from './app/routes/expenses/expenseRedirect';
import { ProvidersIndex } from './app/routes/providers';

const router = wrapCreateBrowserRouter(createBrowserRouter)([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorFallback />,
    children: [
      {
        index: true,
        element: <Homepage />,
      },
      {
        path: 'changePassword',
        element: <ChangePassword />,
      },
      {
        path: 'profile',
        element: <EditProfile />,
      },
      {
        path: 'settings',
        element: <EditSettings />,
      },
      {
        path: 'dependents',
        element: <EditDependents />,
      },
      {
        path: 'expenses',
        element: <Expenses />,
      },
      {
        path: 'expenses/new',
        element: <NewExpense />,
      },
      {
        path: 'expenses/:expenseId/edit',
        element: <EditExpense />,
      },
      {
        path: 'bulkAddExpense',
        element: <BulkAddExpense />,
      },
      {
        path: 'incidents',
        element: <IncidentsIndex />,
      },
      {
        path: 'incidents/new',
        element: <IncidentCreate />,
      },
      {
        path: 'incidents/:id',
        element: <IncidentsShow />,
      },
      {
        path: 'incidents/:incidentId/submit',
        element: <IncidentsSubmit />,
        children: [
          { index: true, element: <SubmitHome /> },
          { path: ':id', element: <SubmitShow /> },
          { path: ':submissionId/personal-responsibility', element: <SubmitPersonalResponsibility /> },
        ]
      },
      {
        path: 'incidents/:incidentId/advance-request/new',
        element: <AdvanceRequestCreate />,
      },
      {
        path: 'incidents/:incidentId/advances/:advanceId', 
        element: <AdvanceShow />,
      },
      {
        path: 'incidents/:incidentId/bulkAddExpense',
        element: <BulkAddExpenseToIncident />,
      },
      {
        path: 'expenses/:id',
        element: <ExpenseRedirect />
      },
      {
        path: 'advance-requests/:advanceRequestId',
        element: <AdvanceRequestRedirect />
      },
      {
        path: 'advances/:advanceId',
        element: <AdvanceRedirect />
      },
      {
        path: 'providers/new',
        element: <NewProvider />,
      },
      {
        path: 'expenses/:expenseId/providers/new',
        element: <NewProvider />,
      },
      {
        path: 'providers/:id',
        element: <ProviderShow />,
      },
      {
        path: 'providers/:id/edit',
        element: <EditProvider />,
      },
      {
        path: 'todos',
        element: <Todos />,
      },
      {
        path: 'notifications',
        element: <Notifications />,
      },
      {
        path: 'files/*',
        element: <Files />,
      },
      {
        path: 'help',
        element: <Help />,
      },
      {
        path: 'deleteAccount',
        element: <DeleteAccount />,
      },
      {
        path: 'test',
        element: <Test />,
      },
      {
        path: 'providers',
        element: <ProvidersIndex />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  }
])

function Layout() {
  const isInsidePWA = useMemo(() => {
    const isInsidePWA = window.matchMedia('(display-mode: standalone)').matches
    return isInsidePWA
  }, [])
  
  return (
    <div className={`App container ${isInsidePWA && 'is-inside-pwa'}`}>
      <TopNav />
      <Outlet />
      <AlertBanner />
      <div className='mt-4' />
      <ScrollRestoration />
    </div>
  );
}

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;

/**
 * Advance Requests are nested under Incidents, so we need to redirect to the Incident page.
 */
function AdvanceRequestRedirect() {
  const { advanceRequestId } = useParams()
  const navigate = useNavigate()
  
  const advanceRequest = useAppSelector((s) => s.advances.advanceRequests.find((ar) => ar.id === advanceRequestId))
  
  useEffect(() => {
    if (advanceRequest) {
      navigate(`/incidents/${advanceRequest.incident_id}`)
    }
  }, [advanceRequest])
  
  const [timedOut, setTimedOut] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimedOut(true)
    }, 1000)
    
    return () => clearTimeout(timeout)
  }, [advanceRequest])
  
  if (timedOut) {
    return <NotFound />
  }
  
  return <div>
    <div className='spinner-border' />
    <div>Please wait while we redirect you...</div>
  </div>
}

/**
 * Advances are nested under Incidents, so we need to redirect.
 */
function AdvanceRedirect() {
  const { advanceId } = useParams()
  const navigate = useNavigate()
  
  const advance = useAppSelector((s) => s.advances.advances.find((a) => a.id === advanceId))
  
  useEffect(() => {
    if (advance) {
      navigate(`/incidents/${advance.incident_id}/advances/${advance.id}`)
    }
  }, [advance])
  
  const [timedOut, setTimedOut] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimedOut(true)
    }, 1000)
    
    return () => clearTimeout(timeout)
  }, [advance])
  
  if (timedOut) {
    return <NotFound />
  }
  
  return <div>
    <div className='spinner-border' />
    <div>Please wait while we redirect you...</div>
  </div>
}
