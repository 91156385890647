import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxToolkit';
import { deleteProvider, ProviderModel } from '../../reduxToolkit/providersSlice';

import './index.scss';
import { useConfirm } from '../../providers/confirmDialog';
import { selectDuplicateProvider } from '../../reduxToolkit/selectors/selectDuplicateProvider';
import { mergeDuplicateProvider } from '../../reduxToolkit/actions/mergeDuplicateProvider';

export function ProvidersIndex() {
  const providers = useAppSelector((s) => s.providers?.providers || []);

  return (
    <div className="providers-index">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>All Providers</h1>
        <Link to="/providers/new" className="btn btn-primary">
          Add Provider
        </Link>
      </div>

      {providers.length === 0 ? (
        <div className="alert alert-info">
          You haven't added any healthcare providers yet.
        </div>
      ) : (
        <div className="list-group">
          {providers.map((provider) => {

            return (
              <Link
                key={provider.id}
                to={`/providers/${provider.id}`}
                className="list-group-item list-group-item-action"
              >
                <ProviderListItem provider={provider} />
              </Link>
            )
          })}
        </div>
      )}
    </div>
  );
} 

function ProviderListItem({ provider }: { provider: ProviderModel }) {
  const [confirm, lastConfirm] = useConfirm()
  const dispatch = useAppDispatch()
  
  const duplicateProvider = useAppSelector(selectDuplicateProvider(provider))
  
  const [street, ...rest] = provider.street_address?.split(',') || []
  const formattedAddress = `${street}\n${rest.join(',').trim()}`.trim()
  
  return (
    <div className="providers-index__list-item">
      <div>
        <h5 className="mb-1">{provider.name}</h5>
        {formattedAddress && (
          <p className="mb-1 text-muted">{formattedAddress}</p>
        )}
        {duplicateProvider && (
          <>
            <br />
            <small className="mb-1 text-muted">Possible duplicate of {duplicateProvider.name}</small>
          </>
        )}
      </div>
      <div className="dropdown">
        <button className="btn btn-link" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="material-icons">more_vert</i>
        </button>
        <ul className="dropdown-menu dropdown-menu-end">
          {duplicateProvider && (
            <li>
              <button className="dropdown-item" onClick={() => dispatch(mergeDuplicateProvider({provider, duplicateProvider}))}>
                Merge with duplicate
              </button>
            </li>
          )}
          <li>
            <button className="dropdown-item text-danger" onClick={() => confirm({
              title: 'Delete Provider',
              message: 'Are you sure you want to delete this provider?',
              onConfirm: () => {
                const now = new Date().toISOString()
                dispatch(deleteProvider({ id: provider.id, updated_at: now, deleted_at: now }))
              }
            })}>
              Delete
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}
