import { useState } from "react"
import { iconForContentType } from "../../lib/iconForContentType"
import { AttachmentModel } from "../reduxToolkit/attachmentsSlice"
import { FilePreviewModal } from "./filePreview"
import { useLoadBlobs } from "../hooks/useLoadBlobs"
import { humanize } from "../../lib/util/string"
import Toast from 'react-hot-toast'
import { useAsyncAction } from "../hooks/useAsyncAction"

import './attachment.scss'
import { useClient } from "../providers/supabase"
import { downloadBlob } from "../../lib/util/downloadBlob"

interface AttachmentProps {
  attachment: AttachmentModel
}

export function Attachment({
  attachment,
}: AttachmentProps) {
  const [showPreview, setShowPreview] = useState(false)
  
  const [blobs, { loading, error }] = useLoadBlobs([attachment.blob_key])
  const blob = blobs?.[0]
  
  const client = useClient()
  const [doDownloadState, doDownload] = useAsyncAction(async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    
    if (!blob) {
      return
    }
    
    try {
      await downloadBlob(blob, { client: client })
    } catch (e: any) {
      Toast.error('Error downloading file: ' + e.message)
    }
  })

  const icon = iconForContentType(blob?.content_type)
  
  let inner: React.ReactNode
  if (doDownloadState.loading) {
    inner = <div className="spinner-border spinner-border-sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  } else if (doDownloadState.error) {
    inner = <i className="material-icons">error</i>
  } else {
    inner = <i className="material-icons">file_download</i>
  }

  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <a className={`attachment`} onClick={() => setShowPreview(true)}>
    <i className='material-icons-outlined'>{icon}</i>
    <span className="attachment__name">
      {loading ? 'Loading...' :
        error ? 'Unable to load file' : undefined}
      {blob?.file_name}
    </span>
    
    <span className="attachment__purpose ms-auto d-none d-md-inline">
      {humanize(attachment.purpose)}
    </span>
    
    <button className="btn btn-link" onClick={doDownload}>
      {inner}
    </button>
      
    <FilePreviewModal show={!!showPreview} fileOrBlob={blob} onClose={() => setShowPreview(false)} />
  </a>
}
